import { displayDay, getValueOrDefault } from '../helpers/utility';

/*
  Type : Number
    1 = อีเมลแจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก N วัน
    2 = อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด
    3 = อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด

  DayIndex : Number from 0 to 6
  Hour : Number from 0 to 23

  Duration : Number
    Type = 1
      Duration = Number of days before medias will be deleted
*/

export class EmailNotiModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);

    this.Type = data?.Type? Number(data.Type): 1;

    this.DayIndex = data?.DayIndex? Number(data.DayIndex): 0;
    this.Hour = data?.Hour? Number(data.Hour): 0;

    this.Duration = data?.Duration || data?.Duration===0? Number(data.Duration): null;

    this.Status = data?.Status? Number(data.Status): 0;
  }

  isValid() {
    return this.Id? true: false;
  }

  displayName() {
    if(this.isValid()){
      if(this.Type === 1) return `แจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก ${this.Duration} วัน`;
      else if(this.Type === 2) return 'แจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด';
      else if(this.Type === 3) return 'แจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด';
    }
    return '';
  }
  displayNameStatic(type=1) {
    if(type === 1) return 'แจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก N วัน';
    else if(type === 2) return 'แจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด';
    else if(type === 3) return 'แจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด';
    else return '';
  }

  displayDayHour() {
    if(this.isValid()){
      return `${displayDay(this.DayIndex)} ${String(this.Hour).padStart(2, '0')}:00`;
    }
    return '';
  }

  displayStatus() {
    if(this.isValid()){
      if(this.Status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
}
