const urlMatch = (url, test) => {
    url = url.replace('http://', '').replace('https://', '').replace('www.', '');
    const urls = url.split('/'); return urls[0] === test;
}

const ENV = urlMatch(window.location.href, 'pth-dev.pttplc.com')? 'DEV' 
    : urlMatch(window.location.href, 'pth-sit.pttplc.com')? 'SIT' 
    : urlMatch(window.location.href, 'pth-uat.pttplc.com')? 'UAT' 
    : urlMatch(window.location.href, 'pth.pttplc.com')? 'PRD' 
    : 'LOCAL';

export const SYSTEM_ENV = ENV;
export const APP_URL = ENV === 'DEV'? 'https://pth-dev.pttplc.com/' 
    : ENV === 'SIT'? 'https://pth-sit.pttplc.com/' 
    : ENV === 'UAT'? 'https://pth-uat.pttplc.com/' 
    : ENV === 'PRD'? 'https://pth.pttplc.com/' 
    : 'http://localhost:5200/';
export const API_URL = ENV === 'DEV'? 'https://pth-be-dev.pttplc.com/' 
    : ENV === 'SIT'? 'https://pth-be-sit.pttplc.com/' 
    : ENV === 'UAT'? 'https://pth-be-uat.pttplc.com/' 
    : ENV === 'PRD'? 'https://pth-be.pttplc.com/' 
    : 'http://localhost:5100/';

export const APP_PREFIX = `${process.env.REACT_APP_PREFIX}_${ENV}`;
export const USE_MOCKDATA = process.env.REACT_APP_USE_MOCKDATA === '1';

export const TOKEN_KEY = `${process.env.REACT_APP_TOKEN_KEY}`;
export const REFRESH_KEY = `${process.env.REACT_APP_REFRESH_KEY}`;
