import { connect } from 'react-redux';

function Footer(props) {
  return (<>
    <nav className="footer border-top-1 bcolor-sgray">
      <div className="container">
        <p className="sm fw-400">
          @2024 บริษัท ปตท. จำกัด (มหาชน) สงวนลิขสิทธิ์
        </p>
      </div>
    </nav>
  </>);
}

Footer.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Footer);