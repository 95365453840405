import { API_URL } from './variables';

export const ApiUrls = {
    'app-config': `${API_URL}api/app-config`,
    'get-forgery': `${API_URL}api/get-forgery`,
    'signin': `${API_URL}api/signin`,
    'signin-ad': `${API_URL}api/signin-ad`,
    'signout': `${API_URL}api/signout`,
    'refresh': `${API_URL}api/refresh`,
    'validate': `${API_URL}api/validate`,
    'check-whitelist': `${API_URL}api/check-whitelist`,
    'app-setting': `${API_URL}api/app-setting`,
    'password': `${API_URL}api/password`,
    'my-roles': `${API_URL}api/my-roles`,
    'my-role': `${API_URL}api/my-role`,
    'departments': `${API_URL}api/departments`,
    'department': `${API_URL}api/department`,
    'department-storages': `${API_URL}api/department-storages`,
    'email-notis': `${API_URL}api/email-notis`,
    'email-noti': `${API_URL}api/email-noti`,
    'email-templates': `${API_URL}api/email-templates`,
    'email-template': `${API_URL}api/email-template`,
    'email-template-test/1': `${API_URL}api/email-template-test/1`,
    'email-template-test/2': `${API_URL}api/email-template-test/2`,
    'email-template-test/3': `${API_URL}api/email-template-test/3`,
    'media-settings': `${API_URL}api/media-settings`,
    'users': `${API_URL}api/users`,
    'pd-users': `${API_URL}api/pd-users`,
    'shared-medias': `${API_URL}api/shared-medias`,
    'shared-media': `${API_URL}api/shared-media`,
    'medias': `${API_URL}api/medias`,
    'media': `${API_URL}api/media`,
    'media-pin': `${API_URL}api/media-pin`,
    'media-renews': `${API_URL}api/media-renews`,
    'media-download': `${API_URL}api/media-download`,
    'media-departments': `${API_URL}api/media-departments`,
    'media-folders': `${API_URL}api/media-folders`,
    'media-azure-uploads': `${API_URL}api/media-azure-uploads`,
    'media-azure-update': `${API_URL}api/media-azure-update`,
    'download': `${API_URL}frontend/download`,
    'all-medias': `${API_URL}api/all-medias`,
    'media-users': `${API_URL}api/media-users`,
    'report-total': `${API_URL}api/report-total`,
    'report-department-usage': `${API_URL}api/report-department-usage`,
    'report-media-upload': `${API_URL}api/report-media-upload`,
    'folder': `${API_URL}api/folder`,
    'folder-medias': `${API_URL}api/folder-medias`,
    'media-setting': `${API_URL}api/media-setting`,
    'media-preview': `${API_URL}api/media-preview`,
    'media-detail': `${API_URL}api/media-detail`,
};
