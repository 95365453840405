import { createContext, useState, useEffect } from 'react';
import { isSafeUrl } from '../helpers/utility';

import { apiHeader } from '../helpers/header';
import { API_URL } from '../actions/variables';
import { ApiUrls } from '../actions/url.actions';

const ConfigContext = createContext({
  configReady: false,
  AzureTenants: {
    Tenants: {},
  },
  isValidAzureTenants: false,
});

const isValidAzureTenants = (_fetch, _data) => {
  return _fetch.ok && _fetch.status === 200 && _data && _data.AzureTenants;
}

export const ConfigContextProvider = (props) => {
  const [configReady, setConfigReady] = useState(false);
  const [AzureTenants, setAzureTenants] = useState({ Tenants: {} });

  /* eslint-disable */
  useEffect(() => {
    const onLoad = async () => {
      try {
        const url = ApiUrls['app-config'];
        if(isSafeUrl(url)){
          const _fetch = await fetch(url, {
            method: 'GET',
            headers: apiHeader(),
          });
          const _data = await _fetch.json();
          if(isValidAzureTenants(_fetch, _data)) setAzureTenants(_data.AzureTenants);
        }
      } catch(_) { console.log(`Error GET - ${API_URL}api/app-config`); console.log(_) }
      setConfigReady(true);
    }
    onLoad();
  }, []);
  /* eslint-enable */

  return (
    <ConfigContext.Provider 
      value={{
        configReady: configReady,
        AzureTenants: AzureTenants,
        isValidAzureTenants: Object.keys(AzureTenants).length > 0 
          && Object.keys(AzureTenants.Tenants).length > 0 
          && AzureTenants.Tenants.tenants?.length > 0,
      }} 
    >
      {props.children}
    </ConfigContext.Provider>
  );
}


export default ConfigContext;