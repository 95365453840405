import { getValueOrDefault, displayStorage as _displayStorage } from '../helpers/utility';
import { unescape } from 'html-escaper';

export class MediaSettingModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);

    this.Name = data?.Name? unescape(data.Name): null;
    
    this.Type = data?.Type? unescape(data.Type): null;
    this.Limit = data?.Limit? Number(data.Limit): 0;
    
    this.Status = data?.Status? Number(data.Status): 0;
  }

  isValid() {
    return this.Id? true: false;
  }

  displayStatus() {
    if(this.isValid()){
      if(this.Status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
  
  displayLimit() { return _displayStorage(this.Limit); }
}
