import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import PageLoader from '../components/PageLoader';
import LayoutInternal from '../components/LayoutInternal';
import RedirectPage from '../views/redirect';

export default function RoutesInternal({ permissions={} }) {
  const LazyRoute = ({ element: Element }) => (<Element />);
  return (
    <LayoutInternal>
      <Suspense fallback={(<PageLoader />)}>
        <Routes>
          <Route path="/internal/medias" 
            element={<LazyRoute element={lazy(() => import('../views/internal/MediasPage'))} />} />
          <Route path="/internal/medias/*" 
            element={<LazyRoute element={lazy(() => import('../views/internal/MediasPage'))} />} />
            
          {permissions.PageMediaExpiring === 1? (<>
            <Route path="/internal/expirations/:mediaId" 
              element={<LazyRoute element={lazy(() => import('../views/internal/ExpirationsPage'))} />} />
            <Route path="/internal/expirations" 
              element={<LazyRoute element={lazy(() => import('../views/internal/ExpirationsPage'))} />} />
          </>): (<></>)}
          {permissions.PageReport === 1? (
            <Route path="/internal/reports" 
              element={<LazyRoute element={lazy(() => import('../views/internal/ReportsPage'))} />} />
          ): (<></>)}
          {permissions.PageSettings === 1? (<>
            <Route path="/internal/settings" 
              element={<LazyRoute element={lazy(() => import('../views/internal/SettingsPage'))} />} />
            <Route path="/internal/settings/:tab" 
              element={<LazyRoute element={lazy(() => import('../views/internal/SettingsPage'))} />} />
          </>): (<></>)}

          <Route path="/internal/user" 
            element={<LazyRoute element={lazy(() => import('../views/internal/UserPage'))} />} />
          <Route path="/internal/user/:tab" 
            element={<LazyRoute element={lazy(() => import('../views/internal/UserPage'))} />} />
          <Route path="/internal/user/:tab/:mediaId" 
            element={<LazyRoute element={lazy(() => import('../views/internal/UserPage'))} />} />
        
          <Route path="/redirect" element={<RedirectPage />} />
          <Route path="*" element={<Navigate replace to="/internal/medias" />} />
        </Routes>
      </Suspense>
    </LayoutInternal>
  );
}