import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function FailedDeviceIdPage({ test=false, ...props }) {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: 'Device ID ไม่ถูกต้อง',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    icon: 'error',
  });
  return (
    <section className="auth-01 bg-full">
      <div className="auth-bg" style={{ backgroundImage: `url('/assets/img/bg/05.jpg')` }}></div>
      <div className="auth-filter"></div>
    </section>
  );
};

FailedDeviceIdPage.propTypes = {
  test: PropTypes.bool,
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(FailedDeviceIdPage);