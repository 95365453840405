import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import AuthContext from '../../../context/AuthContext';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { redirectToUrl } from '../../../helpers/utility';
import { Storage } from '../../../helpers/storage';

import { Button } from '@mui/material';

import { SYSTEM_ENV, APP_URL, APP_PREFIX } from '../../../actions/variables';
import { alertLoading } from '../../../actions/alert.actions';
import { userSigninAD } from '../../../actions/user.actions';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export default function ButtonAD({ msalApplication, tenant, app, test=false, ...props }) {
  const dispatch = useDispatch();
  const { onSignin, onSignout } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const signinProcess = async (e) => {
    e.preventDefault();
    try {
      const _url = searchParams.get('url');
      if(_url) Storage.setItem(`${APP_PREFIX}_REDIRECT`, _url);
      await instance.loginRedirect({
        scopes: [ 'User.Read' ],
        prompt: SYSTEM_ENV === 'LOCAL'? 'select_account': 'login',
      }).catch(err => console.log(err));
    } catch(err) { console.log(err) }
  }

  /* eslint-disable */
  useEffect(() => {
    if(isAuthenticated && inProgress === 'none') signinComplete();
  }, [isAuthenticated, inProgress]);
  /* eslint-enable */

  const signinComplete = async () => {
    dispatch(alertLoading(true));
    try {
      if(accounts[0]?.localAccountId){
        const token = await instance.acquireTokenSilent({ ...app.tokenRequest, account: accounts[0] });
        const res = await userSigninAD({
          TenantId: accounts[0].tenantId,
          ClientId: app.client_id,
          AccessToken: token.accessToken,
          LocalAccountId: accounts[0].localAccountId,
          HomeAccountId: accounts[0].homeAccountId,
          Name: accounts[0].name,
          Username: accounts[0].username,
          IdToken: accounts[0].idToken,
        }, test)(dispatch);
        if(res?.accessToken){
          onSignin({
            aToken: res.accessToken,
            rToken: res.refreshToken,
            u: res.user,
            p: res.permissions,
            tenant: tenant,
            app: app,
          });
          const _url = Storage.getItem(`${APP_PREFIX}_REDIRECT`);
          const _redirect = redirectToUrl('authenticated', _url);
          if(_redirect) navigate(_redirect);
          Storage.removeItem(`${APP_PREFIX}_REDIRECT`);
          dispatch(alertLoading(false));
          return;
        }
      }
    } catch(err) { console.log('Error :', err); }
    instance.logoutRedirect({ postLogoutRedirectUri: APP_URL, onRedirectNavigate: (_) => false });
    onSignout();
    dispatch(alertLoading(false));
    return;
  }

  return (
    <Button onClick={signinProcess} size="large" className="w-full" 
      variant="contained" color="primary" disableElevation 
    >
      เข้าสู่ระบบด้วย Azure AD
    </Button>
  );
}
