
import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { checkPreventDefault, getValueOrDefault } from '../../helpers/utility';

import {
  Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import random from 'random';
import PropTypes from 'prop-types';
import { MEDIA_LOADER } from '../../actions/types';
import { connect, useDispatch } from 'react-redux';
import { updateSettings } from '../../actions/app.actions';
import { alertChange, alertLoading } from '../../actions/alert.actions';
import { userFilesUpload, processRead } from '../../actions/user.actions';
import { MediaModel } from '../../models';

import { APP_PREFIX } from '../../actions/variables';
import { Storage } from '../../helpers/storage';
import { isMobile, isTablet, isAndroid } from 'react-device-detect';

const isInvalidUpload = (mediaSettings, isSignedIn, user) => {
  return !mediaSettings.length || !isSignedIn || !user?.Department?.Id;
}

const openStage1 = (stage, files=[]) => stage > 0 && files.length;
const disabledStage1 = (stage) => stage > 2;
const DialogPreview1 = (file, stage) => {
  return (
    <div className="upload-preview bradius ovf-hidden border-1 bcolor-hgray mt-2">
      <div className="wrapper">
        {file.type.includes('image')? (
          <img className="bradius" src={file.preview} alt="File" />
        ): file.type.includes('video')? (
          <video className="bradius" muted={true} src={file.preview}></video>
        ): (<></>)}
      </div>
      <div className={`uploader-status ${stage > 1? 'active': ''}`}>
        <div className="wrapper">
          {file.error? (<p className="fw-600 color-danger">{file.error}</p>) 
            : stage === 2? (<CheckCircleIcon color="primary" sx={{ fontSize: '2.5rem' }} />) 
            : (<></>)}
        </div>
      </div>
    </div>
  );
}
const ComponentDialog1 = (htmlFor, files, onFileChange, onFilesDelete, stage, onSubmit, onCancel) => {
  if(!files.length) return (<></>);
  return (
    <Dialog onClose={onCancel} 
      open={openStage1(stage, files)} 
      fullWidth={true} maxWidth="md" scroll="paper" 
      PaperProps={{ component: 'form', onSubmit: onSubmit }} 
    >
      <DialogTitle>
        <span className="h5 fw-600 lh-sm">อัปโหลดมีเดีย</span>
      </DialogTitle>
      <DialogContent className="with-grids pb-2" dividers={true}>
        <div className="grids">
          {[1].map(k => {
            const _fileErrors = files.filter(d => d.error);
            if(stage < 2 || !_fileErrors.length) return (<></>);
            return (
              <div className="grid sm-100 pb-2" key={`ediv_${k}`}>
                <p className="lg fw-500 color-danger">
                  มีปัญหาทั้งสิ้น {_fileErrors.length} ไฟล์{' - '}
                  <u className="a c-pointer h-color-p" 
                    onClick={e => onFilesDelete(e, _fileErrors.map((_, i) => i))} 
                  >ลบทั้งหมด</u>
                </p>
              </div>
            );
          })}
          {files.map((d, i) => (
            <div key={`file_${i}`} className="grid pb-4">
              <div className="d-flex">
                <OutlinedInput variant="outlined" size="small" className="w-full pl-1" 
                  value={getValueOrDefault(d.name)} placeholder="ชื่อมีเดีย" 
                  onChange={e => onFileChange(i, 'name', e.target.value)} 
                  startAdornment={
                    <IconButton size="small" disabled={true}>
                      <InsertDriveFileIcon />
                    </IconButton>
                  } 
                />
                <div className="d-flex ml-2" style={{ minWidth: '40px' }}>
                  <Button onClick={e => onFilesDelete(e, [i])} 
                    variant="contained" color="error" disableElevation 
                    sx={{ minWidth: 0 }} 
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </div>
              </div>
              {DialogPreview1(d, stage)}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions className="jc-start">
        <div className="d-flex fw-wrap" style={{ margin: '-8px 0 0 0' }}>
          <Button data-testid="button01" onClick={onSubmit} 
            disabled={disabledStage1(stage)} className="btn-mw" disableElevation 
            variant="contained" color="primary" style={{ margin: '8px 8px 0 0' }} 
            startIcon={<CameraAltIcon />} 
          >{stage === 3? 'กำลังอัปโหลด': 'อัปโหลด'}</Button>
          <Button htmlFor={htmlFor} component="label" disabled={disabledStage1(stage)} 
            className="btn-mw" variant="contained" color="secondary" disableElevation 
            startIcon={<AddIcon />} style={{ margin: '8px 8px 0 0' }} 
          >เพิ่มมีเดีย</Button>
          <Button data-testid="button02" onClick={onCancel} 
            disabled={disabledStage1(stage)} className="btn-mw" disableElevation 
            variant="contained" color="default" style={{ margin: '8px 8px 0 0' }} 
          >ยกเลิก</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const uploadCheckErrors = async (accessToken, files=[], settings={}, mediaSettings=[], test=false) => {
  let usedStorage = 0;
  const department = await processRead(accessToken, 'department', {}, test)();
  const departmentStorage = department.Storage - department.UsedStorage;
  const systemStorage = Number(settings['APP_STORAGE'] || '0') - Number(settings['APP_USED_STORAGE'] || '0');
  return files.map(d => {
    const _file = d.file;
    usedStorage += _file.size;
    const temp = mediaSettings.find(k => k.Type.includes(_file.type));
    if(!d.name) return { ...d, error: 'กรุณากรอกชื่อมีเดีย' };
    if(!temp) return { ...d, error: 'ไม่รองรับรูปแบบมีเดีย' };
    if(usedStorage > systemStorage) return { ...d, error: 'พื้นที่ความจำระบบไม่เพียงพอ' };
    if(usedStorage > departmentStorage) return { ...d, error: 'พื้นที่ความจำส่วนงานไม่เพียงพอ' };
    if(_file.size > temp.Limit) return { ...d, error: `มีเดียมีขนาดใหญ่เกิน ${temp.displayLimit()}` };
    return { ...d, error: '' };
  });
}

function MediaUpload({
  test=false, onMobileNav=false, settings={}, mediaSettings=[],
  folder=new MediaModel(), onClick=()=>{}, ...props
}) {
  const dispatch = useDispatch();
  const { isSignedIn, accessToken, user } = useContext(AuthContext);

  const specialDevice = isMobile || isTablet || isAndroid;
  const _rand = `upload_file_${random.int(0, 10**7)}`;

  const ref = useRef(null);
  const [stage, setStage] = useState(0);
  const [files, setFiles] = useState([]);

  const allowTypes = mediaSettings.map(k => k.Type).join(',').split(',').map(k => k.replace('image/', '').replace('video/', '').replace('+xml', ''));
  const onChange = async (_files) => {
    if(stage > 2 || !user?.Department?.Id) return;
    const _invalidFiles = [];
    const _validFiles = Object.keys(_files).map(d => {
      const _names = _files[d].name.split('.');
      const _mime = _names.pop();
      if(allowTypes.indexOf(_mime.toLowerCase()) < 0){
        _invalidFiles.push(_files[d].name); return null;
      }
      return {
        file: _files[d],
        mime: _mime,
        name: _names.join(''),
        type: _mime ===  'mov'? 'video/quicktime' :_files[d].type,
        preview: URL.createObjectURL(_files[d]),
        error: '',
      };
    }).filter(k => k);
    if(ref.current) ref.current.value = '';
    if(_invalidFiles.length){
      alertChange('Danger', 'มีประเภทไฟล์ที่ไม่รองรับ', [_invalidFiles.join(', ')])(dispatch);
    }
    const _allFiles = [ ...files, ..._validFiles ];
    if(_allFiles.length){
      setFiles(_allFiles); setStage(1);
      if(specialDevice) onSubmit(null, 1, _allFiles);
    }
    return;
  }
  const onCancel = (e=null, force=false) => {
    checkPreventDefault(e);
    if(!force && stage > 2) return;
    setFiles([]); setStage(0); return;
  }
  const onSubmit = async (e=null, _stage=null, _files=null) => {
    checkPreventDefault(e);
    let thisStage = getValueOrDefault(_stage, stage);
    let thisFiles = getValueOrDefault(_files, files);
    if(thisStage > 2 || !user?.Department?.Id || !thisFiles.length) return;

    thisFiles = await uploadCheckErrors(accessToken, thisFiles, settings, mediaSettings, test);
    thisFiles.sort((a, _) => a.error? -1: 1);
    setFiles(thisFiles); setStage(2);

    const hasErrors = thisFiles.filter(d => d.error);
    if(hasErrors.length){
      if(specialDevice){
        alertChange('Danger', 'อัปโหลดมีเดียไม่สำเร็จ', hasErrors.map(d => d.error).filter((d, i, arr) => arr.indexOf(d) === i))(dispatch);
        onCancel(null, true);
      }
      return;
    }

    setStage(3);
    if(specialDevice) alertLoading(true)(dispatch);
    try {
      const _files = thisFiles.map(d => new File([ d.file ], `${d.name}.${d.mime}`, { type: d.type }));
      let _folderId = folder?.InFolder?.Id? folder.Id: 0;
      if(onMobileNav) _folderId = Storage.getItem(`${APP_PREFIX}_IN_FOLDER_ID`) || _folderId;
      await userFilesUpload(accessToken, _folderId, _files, test)(specialDevice? null: dispatch);
      dispatch({ type: MEDIA_LOADER });
      onCancel(null, true);
      updateSettings(accessToken)();
      if(specialDevice) ref?.current?.click();
    } catch(err) {
      alertChange('Danger', `${err}`)(dispatch);
      onCancel(null, true);
    }
    if(specialDevice) alertLoading(false)(dispatch);
    return;
  }

  const onFileChange = (i, key, value) => {
    let _files = [ ...files ];
    if(_files[i]) _files[i][key] = value;
    setFiles(_files);
  }
  const onFilesDelete = (e, arr) => {
    e.preventDefault();
    const _files = files.filter((_, i) => arr.indexOf(i) < 0);
    setFiles(_files);
    if(!_files.length) onCancel(null, true);
  }
  
  /* eslint-disable */
  useEffect(() => { if(accessToken) updateSettings(accessToken)(); }, [accessToken]);
  /* eslint-enable */

  if(isInvalidUpload(mediaSettings, isSignedIn, user)) return (<></>);
  return (<>
    <input data-testid="input" type="file" ref={ref} id={_rand} 
      accept={[ ...mediaSettings.map(d => d.Type), 'text/plain' ].join(',')} 
      multiple={true} capture="environment" 
      onChange={e => onChange(e.target.files)} 
      className="pos-absolute op-0 pe-none" 
    />
    <label htmlFor={_rand} onClick={onClick} 
      className="pos-absolute c-pointer" data-testid="labelClick" 
      style={{ top: 0, bottom: 0, left: 0, right: 0 }} 
    ></label>

    {specialDevice? (<></>): (
      ComponentDialog1(_rand, files, onFileChange, onFilesDelete, stage, onSubmit, onCancel)
    )}
  </>);
}

MediaUpload.propTypes = {
  test: PropTypes.bool,
  onMobileNav: PropTypes.bool,
  folder: PropTypes.instanceOf(MediaModel),
  onClick: PropTypes.func,
};
const mapStateToProps = (state) => ({
  settings: state.app.settings,
  mediaSettings: state.app.mediaSettings,
});
export default connect(mapStateToProps, {})(MediaUpload);