import { isSafeUrl } from '../helpers/utility';
import { apiHeader } from '../helpers/header';
import { APP_PREFIX } from './variables';
import {
  APP_INIT, APP_SETTINGS, APP_SCREEN_WIDTH, MENU_ACTIVE, MEDIA_SETTINGS,
} from './types';
import { MediaSettingModel } from '../models';
import { Storage } from '../helpers/storage';
import { ApiUrls } from './url.actions';

export const updateAppInit = (val=true) => async (dispatch=null) => {
  if(dispatch) dispatch({ type: APP_INIT, payload: val });
  return true;
}

export const updateSettings = (accessToken='') => async (dispatch=null) => {
  const url = ApiUrls['app-setting'];
  if(accessToken && isSafeUrl(url)){
    try {
      const _fetch = await fetch(url, {
        method: 'GET',
        headers: apiHeader(accessToken),
      });
      if(_fetch.ok && _fetch.status === 200){
        const _data = await _fetch.json();
        if(dispatch) dispatch({ type: APP_SETTINGS, payload: _data });
        return true;
      }
    } catch(_) {}
  }
  if(dispatch) dispatch({ type: APP_SETTINGS, payload: {} });
  return false;
}

export const updateScreenWidth = (screenWidth) => async (dispatch=null) => {
  try {
    if(dispatch) dispatch({ type: APP_SCREEN_WIDTH, payload: screenWidth });
    return true;
  } catch(_) {}
  return false;
}
export const updateMenuActive = (index=0) => (dispatch=null) => {
  if(dispatch) dispatch({ type: MENU_ACTIVE, payload: index });
  return true;
}

export const updateMediaSettings = (accessToken='') => async (dispatch=null) => {
  const url = ApiUrls['media-settings'];
  if(accessToken && isSafeUrl(url)){
    try {
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeader(accessToken),
      });
      if(_fetch.ok && _fetch.status === 200){
        const _data = await _fetch.json();
        if(dispatch){
          dispatch({
            type: MEDIA_SETTINGS,
            payload: (_data?.Result ?? []).map(d => new MediaSettingModel(d)),
          });
        }
        return true;
      }
    } catch(_) {}
  }
  if(dispatch) dispatch({ type: MEDIA_SETTINGS, payload: [] });
  return false;
}

export const checkDeviceId = async (searchParams) => {
  try {
    const deviceId = searchParams.get('deviceId') || Storage.getItem(`${APP_PREFIX}_DEVICE_ID`);
    if(!deviceId) return false;

    Storage.setItem(`${APP_PREFIX}_DEVICE_ID`, deviceId);
    const url = ApiUrls['check-whitelist'];
    if(!isSafeUrl(url)) return false;

    const _fetch = await fetch(url, {
      method: 'PATCH',
      headers: apiHeader(),
      body: JSON.stringify({ DeviceId: deviceId }),
    });
    const _data = await _fetch.json();
    return _fetch.ok && _fetch.status === 200 && _data?.Result;
  } catch(_) {}
  return false;
}
