import { getValueOrDefault, displayStorage as _displayStorage } from '../helpers/utility';
import { unescape } from 'html-escaper';

export class DepartmentModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);
    
    this.Code = getValueOrDefault(data?.Code, null);
    this.Name = data?.Name? unescape(data.Name): null;
    this.Status = data?.Status? Number(data.Status): 0;
    
    this.Storage = Number(data?.Storage || 0);
    this.MinStorage = Number(data?.MinStorage || 0);
    this.UsedStorage = Number(data?.UsedStorage || 0);
  }

  isValid() {
    return this.Id? true: false;
  }
  
  displayStatus() {
    if(this.isValid()){
      if(this.Status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }
  
  displayStorage() { return _displayStorage(this.Storage); }
  displayMinStorage(){ return _displayStorage(this.MinStorage); }
  displayUsedStorage(){ return _displayStorage(this.UsedStorage); }
}
