import { isSafeUrl, ifNull } from '../helpers/utility';
import { API_URL, USE_MOCKDATA } from './variables';
import { FORCE_SIGNOUT } from './types';
import { alertChange, alertLoading } from './alert.actions';
import { getForgery, apiHeader, apiHeaderFormData } from '../helpers/header';
import { ApiUrls } from './url.actions';
import {
  PaginateModel,
  EmailTemplateModel, EmailNotiModel,
  DepartmentModel, UserRoleModel, UserModel,
  MediaSettingModel, MediaModel,
} from '../models';

import {
  MOCK_REPORT_TOTAL, MOCK_REPORT_USAGE,
  MOCK_EMAIL_TEMPLATES, MOCK_EMAIL_NOTIS,
  MOCK_DEPARTMENTS, MOCK_USER_ROLES, MOCK_USERS, MOCK_USER,
  MOCK_MEDIA_SETTINGS, MOCK_FOLDERS, MOCK_MEDIAS,
} from '../mock';

const dispatchHelper = (dispatch=null, process) => { if(dispatch) dispatch(process); }

const useMockData = (test) => test || USE_MOCKDATA;
const isFetchFailed = (_fetch) => !_fetch.ok || _fetch.status !== 200;
const alertMessage = (_data, _default='') => _data?.Message ?? _default;
const alertError = (_data) => _data?.Error ?? [];


export const userSignin = (input={}, test=false) => async (dispatch=null) => {
  if(useMockData(test)){
    dispatchHelper(dispatch, alertLoading(false));
    return {
      accessToken: 'MOCKDATA',
      refreshToken: 'MOCKDATA',
      permissions: {},
      user: new UserModel({
        ...MOCK_USER(),
        Username: input?.Username ?? 'SuperAdmin',
      }),
    };
  }

  const url = ApiUrls['signin'];
  if(isSafeUrl(url)){
    dispatchHelper(dispatch, alertLoading(true));
    try {
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(input),
      });
      const _data = await _fetch.json();
      if(isFetchFailed(_fetch)){
        dispatchHelper(dispatch, alertChange('Warning', alertMessage(_data, 'เข้าสู่ระบบไม่สำเร็จ'), alertError(_data)));
        return { accessToken: null, refreshToken: null, user: null, permissions: {} };
      }
      
      dispatchHelper(dispatch, alertLoading(false));
      return {
        accessToken: ifNull(_data?.User.AccessToken),
        refreshToken: ifNull(_data?.User.RefreshToken),
        user: new UserModel(ifNull(_data?.User, {})),
        permissions: {},
      };
    } catch(_) {}
  }
  dispatchHelper(dispatch, alertChange('Warning', 'เข้าสู่ระบบไม่สำเร็จ'));
  return { accessToken: null, refreshToken: null, user: null, permissions: {} };
}
export const userSignout = (accessToken='', test=false, type=1) => async (dispatch=null) => {
  const url = ApiUrls['signout'];
  if(test || !accessToken || !isSafeUrl(url)) return true;
  dispatchHelper(dispatch, alertLoading(true));
  try {
    const _forgery = await getForgery(accessToken);
    await fetch(url, {
      method: 'PATCH',
      headers: apiHeader(accessToken, _forgery),
      credentials: 'include',
    });
  } catch(_) {}
  if(type === 2) dispatchHelper(dispatch, alertChange('Danger', 'มีผู้เข้าใช้งานโดยใช้บัญชีเดียวกัน <br /> อยู่ในขณะนี้'));
  else dispatchHelper(dispatch, alertChange('Info', 'ออกจากระบบสำเร็จแล้ว'));
  return true;
}

export const userSigninAD = (input={}, test=false) => async (dispatch=null) => {
  if(useMockData(test)){
    dispatchHelper(dispatch, alertLoading(false));
    return {
      accessToken: 'MOCKDATA',
      refreshToken: 'MOCKDATA',
      permissions: {},
      user: new UserModel({
        ...MOCK_USER(),
        Username: input?.Username ?? 'SuperAdmin',
      }),
    };
  }

  const url = ApiUrls['signin-ad'];
  if(isSafeUrl(url)){
    dispatchHelper(dispatch, alertLoading(true));
    try {
      const _forgery = await getForgery();
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeader('', _forgery),
        body: JSON.stringify(input),
        credentials: 'include',
      });
      const _data = await _fetch.json();
      if(isFetchFailed(_fetch)){
        dispatchHelper(dispatch, alertChange('Warning', alertMessage(_data, 'เข้าสู่ระบบไม่สำเร็จ'), alertError(_data)));
        return { accessToken: null, refreshToken: null, user: null, permissions: {} };
      }
      
      dispatchHelper(dispatch, alertLoading(false));
      return {
        accessToken: ifNull(_data?.User.AccessToken),
        refreshToken: ifNull(_data?.User.RefreshToken),
        user: new UserModel(ifNull(_data?.User, {})),
        permissions: ifNull(_data?.Permissions, {}),
      };
    } catch(err) {
      dispatchHelper(dispatch, alertChange('Warning', 'เข้าสู่ระบบไม่สำเร็จ', [ `${err}`]));
    }
  }
  return { accessToken: null, refreshToken: null, user: null, permissions: {} };
}

export const dispatchSignout = (dispatch=null) => {
  if(dispatch) dispatch({ type: FORCE_SIGNOUT, payload: 1 });
}
export const checkAuthentication = async (accessToken='', dispatch=null) => {
  const url = ApiUrls['validate'];
  if(!accessToken || !dispatch || !isSafeUrl(url)) return true;
  try {
    const _fetch = await fetch(url, {
      method: 'GET',
      headers: apiHeader(accessToken),
    });
    const _data = await _fetch.json();
    if(!_data?.Result){ dispatch({ type: FORCE_SIGNOUT, payload: 2 }); return false; }
  } catch(_) {}
  return true;
}


export const userFilesUpload = (accessToken='', folderId=0, files=[], test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    const url = ApiUrls['media-azure-uploads'] + `/${folderId}`;
    if(isSafeUrl(url)){
      dispatchHelper(dispatch, alertLoading(true));
      try {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
        
        const _forgery = await getForgery(accessToken);
        const _fetch = await fetch(url, {
          method: 'POST',
          headers: apiHeaderFormData(accessToken, _forgery),
          body: formData,
          credentials: 'include',
        });
        const _data = await _fetch.json();
        if(isFetchFailed(_fetch)){
          dispatchHelper(dispatch, alertChange('Warning', alertMessage(_data, 'อัปโหลดมีเดียไม่สำเร็จ')));
          resolve(_data); return false;
        }
        
        dispatchHelper(dispatch, alertChange('Info', 'อัปโหลดมีเดียสำเร็จ'));
        resolve(_data); return true;
      } catch (err) {
        console.log(`Error POST - ${API_URL}api/media-azure-uploads/${folderId}`);
        console.log(err);
        dispatchHelper(dispatch, alertChange('Warning', 'อัปโหลดมีเดียไม่สำเร็จ', [ err ]));
        resolve(err); return false;
      }
    } resolve(false); return false;
  });
}
export const userFileUpdate = (accessToken='', mediaId=0, file, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    const url = ApiUrls['media-azure-update'] + `/${mediaId}`;
    if(isSafeUrl(url)){
      dispatchHelper(dispatch, alertLoading(true));
      try {
        const formData = new FormData();
        formData.append('file', file)
        
        const _forgery = await getForgery(accessToken);
        const _fetch = await fetch(url, {
          method: 'POST',
          headers: apiHeaderFormData(accessToken, _forgery),
          body: formData,
          credentials: 'include',
        });
        const _data = await _fetch.json();
        if(isFetchFailed(_fetch)){
          dispatchHelper(dispatch, alertChange('Warning', alertMessage(_data, 'แก้ไขมีเดียไม่สำเร็จ')));
          resolve(_data); return false;
        }
        
        dispatchHelper(dispatch, alertChange('Info', 'แก้ไขมีเดียสำเร็จ'));
        resolve(new MediaModel(_data)); return true;
      } catch (err) {
        console.log(`Error POST - ${API_URL}api/media-azure-update/${mediaId}`);
        console.log(err);
        dispatchHelper(dispatch, alertChange('Warning', 'แก้ไขมีเดียไม่สำเร็จ', [ err ]));
        resolve(err); return false;
      }
    } resolve(false); return false;
  });
}

const bufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return btoa(binary);
}
export const userImageFile = (accessToken='', media=new MediaModel(), test=false) => async () => {
  return new Promise(async resolve => {
    const url = ApiUrls['media-detail'] + `/${media.Id}/${media.OriginalName}`;
    if(!isSafeUrl(url)){ resolve(null); return null; }
    try{
      const _forgery = await getForgery(accessToken);
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': media.Mimetype, 'X-CSRF-TOKEN': _forgery,
          'Authorization': `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });
      const _buffer = await _fetch.arrayBuffer();
      const _base64 = bufferToBase64(_buffer);
      const result = `data:${media.Mimetype.includes('video')? 'video/mp4': media.Mimetype};base64,${_base64}`;
      resolve(result); return result;
    } catch(_) {} resolve(null); return null;
  });
}


const getProcessData = async (_fetch, type='bypass', excepts=[]) => {
  let _data = null;
  if(excepts.indexOf(type) < 0) try { _data = await _fetch.json(); } catch(_) {}
  return _data;
}
const isProcessExport = (type='', _data) => {
  return type.includes('export-') && _data?.data?.fileName;
}

const sortUserRoles = (a='', b='') => {
  const _roles = ['User', 'Admin Division', 'VP UP', 'Super Admin', 'System Admin'];
  let _order = 0;
  for(let i=0; i<_roles.length; i++){
    if(a === _roles[i]){ _order = -1; break; }
    else if(b === _roles[i]){ _order = 1; break; }
  }
  return _order;
}

const processListMockData = (type='') => {
  if(['email-templates'].indexOf(type) > -1) return MOCK_EMAIL_TEMPLATES();
  else if(['email-notis'].indexOf(type) > -1) return MOCK_EMAIL_NOTIS();
  else if(['departments', 'media-departments'].indexOf(type) > -1) return MOCK_DEPARTMENTS();
  else if(['user-roles', 'my-roles'].indexOf(type) > -1) return MOCK_USER_ROLES();
  else if(['users', 'media-users'].indexOf(type) > -1) return MOCK_USERS();
  else if(['media-settings'].indexOf(type) > -1) return MOCK_MEDIA_SETTINGS();
  else if(['folders', 'media-folders'].indexOf(type) > -1) return MOCK_FOLDERS();
  else if(['medias', 'all-medias', 'shared-medias'].indexOf(type) > -1) return MOCK_MEDIAS();
  return [];
}
export const _processListMockData = (type='') => processListMockData(type);
const processListCleanData = (type='', data=[]) => {
  let _result = data;
  if(['email-templates'].indexOf(type) > -1){
    _result = data.map(d => new EmailTemplateModel(d));
  }else if(['email-notis'].indexOf(type) > -1){
    _result = data.map(d => new EmailNotiModel(d));
  }else if(['departments', 'media-departments', 'pd-departments'].indexOf(type) > -1){
    _result = data.map(d => new DepartmentModel(d));
  }else if(['user-roles', 'my-roles'].indexOf(type) > -1){
    _result = data.map(d => new UserRoleModel(d));
    _result.sort((a, b) => sortUserRoles(a.Name, b.Name));
  }else if(['users', 'media-users', 'pd-users'].indexOf(type) > -1){
    _result = data.map(d => new UserModel(d));
  }else if(['media-settings'].indexOf(type) > -1){
    _result = data.map(d => new MediaSettingModel(d));
  }else if(['folders', 'media-folders'].indexOf(type) > -1){
    _result = data.map(d => new MediaModel(d));
  }else if(['medias', 'all-medias', 'shared-medias'].indexOf(type) > -1){
    _result = data.map(d => new MediaModel(d));
  }
  return _result;
}
export const _processListCleanData = (type='', data=[]) => processListCleanData(type, data);
export const processList = (accessToken='', type='', input={}, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    if(useMockData(test)){
      let _result = processListMockData(type);
      let _paginate = new PaginateModel(ifNull(input?.Paginate, { Pp: _result.length }));
      _paginate.Total = _result.length;
      let res = {
        paginate: _paginate,
        dataFilter: ifNull(input?.DataFilter, {}),
        result: _result.filter((_, i) => i >= _paginate.start() && i < _paginate.end()),
      };
      resolve(res); return true;
    }

    const url = ApiUrls[type];
    if(!isSafeUrl(url)){ resolve(false); return false; }

    dispatchHelper(dispatch, alertLoading(true));
    try {
      const _forgery = await getForgery(accessToken);
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeader(accessToken, _forgery),
        body: JSON.stringify(input),
        credentials: 'include',
      });
      const _data = await _fetch.json();
      if(isFetchFailed(_fetch)){
        dispatchHelper(dispatch, alertLoading(false));
        resolve(_data); return false;
      }

      let res = {
        paginate: new PaginateModel(ifNull(_data?.Paginate, {})),
        dataFilter: ifNull(_data?.DataFilter, {}),
        result: ifNull(_data?.Result, []),
      };
      if(isProcessExport(type, _data)){
        res.result = ApiUrls['download']  + `/${_data?.data?.fileName}`;
        if(isSafeUrl(res.result)) window.open(res.result);
        dispatchHelper(dispatch, alertLoading(false));
        resolve(res); return true;
      }
      
      res.result = processListCleanData(type, res.result);
      dispatchHelper(dispatch, alertLoading(false));
      resolve(res); return true;
    } catch(err) {
      console.log(`Error POST - ${API_URL}api/${type}`);
      console.log(err);
      dispatchHelper(dispatch, alertLoading(false));
      resolve(err); return false;
    }
  });
}

const processReadMockData = (type='') => {
  if(type === 'department') return MOCK_DEPARTMENTS()[0];
  else if(type === 'report-total') return MOCK_REPORT_TOTAL();
  else if(type === 'report-usage') return MOCK_REPORT_USAGE();
  return [];
}
export const _processReadMockData = (type='') => processReadMockData(type);
const processReadCleanData = (type='', _data={}) => {
  let _result = ifNull(_data);
  if(type === 'department'){
    _result = new DepartmentModel(ifNull(_result, {}));
  }else if(type.indexOf('media/') === 0){
    _result = new MediaModel(ifNull(_result, {}));
  }else if(type.indexOf('all-media/') === 0){
    _result = new MediaModel(ifNull(_result, {}));
  }else if(type.indexOf('shared-media/') === 0){
    _result = new MediaModel(ifNull(_result, {}));
  }
  return _result;
}
export const _processReadCleanData = (type='', _data={}) => processReadCleanData(type, _data);
const processReadHasId = (input) => input && input['Id'];
const processReadHasValue = (input, k) => k !== 'Id' && (input[k] || input[k]===0);
export const processRead = (accessToken='', type='', input={}, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    if(useMockData(test)){
      let result = processReadMockData(type);
      resolve(result); return true;
    }

    let url = ApiUrls[type];
    if(!isSafeUrl(url)){ resolve(false); return false; }

    dispatchHelper(dispatch, alertLoading(true));
    try {
      if(processReadHasId(input)){ url += `/${input.Id}`; delete input.Id; }
      let sep = '?';
      Object.keys(input).forEach(k => {
        if(processReadHasValue(input, k)){ url += `${sep}${k}=${input[k]}`; sep = '&'; }
      });

      const _fetch = await fetch(url, {
        method: 'GET',
        headers: apiHeader(accessToken),
      });
      const _data = await _fetch.json();
      if(isFetchFailed(_fetch)){
        dispatchHelper(dispatch, alertLoading(false));
        resolve(_data); return false;
      }
      
      let result = processReadCleanData(type, _data);

      dispatchHelper(dispatch, alertLoading(false));
      resolve(result); return true;
    } catch(err) {
      console.log(`Error GET - ${API_URL}api/${type}`);
      console.log(err);
      dispatchHelper(dispatch, alertLoading(false));
      resolve(err); return false;
    }
  });
}

const processAlert = (type='Info', title='สร้างข้อมูลไม่สำเร็จ', data={}) => {
  return alertChange(type, data?.Message || title, [data?.Error || '']);
}

export const processCreate = (accessToken='', type='', input={}, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    if(useMockData(test)){
      dispatchHelper(dispatch, alertChange('Info', 'สร้างข้อมูลสำเร็จ'));
      resolve(true); return true;
    }

    const url = ApiUrls[type];
    if(!isSafeUrl(url)){ resolve(false); return false; }

    dispatchHelper(dispatch, alertLoading(true));
    try {
      const _forgery = await getForgery(accessToken);
      const _fetch = await fetch(url, {
        method: 'POST',
        headers: apiHeader(accessToken, _forgery),
        body: JSON.stringify(input),
        credentials: 'include',
      });
      const _data = await getProcessData(_fetch, type, ['media-download']);
      if(isFetchFailed(_fetch)){
        dispatchHelper(dispatch, processAlert('Warning', 'สร้างข้อมูลไม่สำเร็จ', _data));
        resolve(false); return false;
      }
      
      if(type === 'media-download' && input?.Media){
        const _blob = await _fetch.blob();
        const mimetype = input.Media.OriginalName.split('.').pop();
        const fileName = input.Media.FileName 
          .replace(`.${mimetype}`, '').replace(/\./g, '-').trim()+`.${mimetype}`;
        const _link = document.createElement('a');
        _link.href = URL.createObjectURL(new Blob([_blob]));
        _link.setAttribute('download', fileName);
        document.body.appendChild(_link);
        _link.click();
        document.body.removeChild(_link);
      }
      
      dispatchHelper(dispatch, processAlert('Info', 'สร้างข้อมูลสำเร็จ', _data));
      const _return = ifNull(_data?.Result, true);
      resolve(_return); return _return;
    } catch(err) {
      console.log(`Error POST - ${API_URL}api/${type}`);
      console.log(err);
      dispatchHelper(dispatch, alertChange('Danger', 'เกิดข้อผิดพลาดในระบบ', [ err ]));
      resolve(err); return false;
    }
  });
}
export const processUpdate = (accessToken='', type='', input={}, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    if(useMockData(test)){
      dispatchHelper(dispatch, alertChange('Info', 'แก้ไขข้อมูลสำเร็จ'));
      resolve(true); return true;
    }

    const url = ApiUrls[type];
    if(isSafeUrl(url)){
      dispatchHelper(dispatch, alertLoading(true));
      try {
        const _forgery = await getForgery(accessToken);
        const _fetch = await fetch(url, {
          method: 'PATCH',
          headers: apiHeader(accessToken, _forgery),
          body: JSON.stringify(input),
          credentials: 'include',
        });
        const _data = await getProcessData(_fetch);
        if(isFetchFailed(_fetch)){
          dispatchHelper(dispatch, processAlert('Warning', 'แก้ไขข้อมูลไม่สำเร็จ', _data));
          resolve(false); return false;
        }

        dispatchHelper(dispatch, processAlert('Info', 'แก้ไขข้อมูลสำเร็จ', _data));
        resolve(true); return true;
      } catch(err) {
        console.log(`Error PATCH - ${API_URL}api/${type}`);
        console.log(err);
        dispatchHelper(dispatch, alertChange('Danger', 'เกิดข้อผิดพลาดในระบบ', [ err ]));
        resolve(err); return false;
      }
    } resolve(false); return false;
  });
}
export const processDelete = (accessToken='', type='', input={}, test=false) => async (dispatch=null) => {
  return new Promise(async (resolve) => {
    if(useMockData(test)){
      dispatchHelper(dispatch, alertChange('Info', 'ลบข้อมูลสำเร็จ'));
      resolve(true); return true;
    }

    const url = ApiUrls[type];
    if(isSafeUrl(url)){
      dispatchHelper(dispatch, alertLoading(true));
      try {
        const _forgery = await getForgery(accessToken);
        const _fetch = await fetch(url, {
          method: 'DELETE',
          headers: apiHeader(accessToken, _forgery),
          body: JSON.stringify(input),
          credentials: 'include',
        });
        const _data = await getProcessData(_fetch);
        if(isFetchFailed(_fetch)){
          dispatchHelper(dispatch, processAlert('Warning', 'ลบข้อมูลไม่สำเร็จ', _data));
          resolve(false); return false;
        }
        
        dispatchHelper(dispatch, processAlert('Info', 'ลบข้อมูลสำเร็จ', _data));
        resolve(true); return true;
      } catch(err) {
        console.log(`Error DELETE - ${API_URL}api/${type}`);
        console.log(err);
        dispatchHelper(dispatch, alertChange('Danger', 'เกิดข้อผิดพลาดในระบบ', [ err ]));
        resolve(err); return false;
      }
    } resolve(false); return false;
  });
}
