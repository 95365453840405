// App
export const APP_INIT = 'app/init';
export const APP_SETTINGS = 'app/settings';
export const APP_SCREEN_WIDTH = 'app/screen-width';
export const MENU_ACTIVE = 'menu/active';
export const MEDIA_LOADER = 'media/loader';
export const MEDIA_LOADER_RESET = 'media/loader-reset';
export const MEDIA_SETTINGS = 'media/settings';

// Alert
export const ALERT_LOADING = 'alert/loading';

// Auth
export const FORCE_SIGNOUT = 'force/signout';
