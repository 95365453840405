import { connect } from 'react-redux';

function PageLoader(props) {
  return (
    <div className="global-loader active">
      <div className="loader">
        <div className="loader-wrapper">
          <div className="arrow prev">‹</div>
          <div className="text">Loading</div>
          <div className="arrow next">‹</div>
        </div>
      </div>
    </div>
  );
}

PageLoader.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(PageLoader);