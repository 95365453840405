import CryptoJS from 'crypto-js';
import { APP_URL, APP_PREFIX, TOKEN_KEY } from '../actions/variables';
import { BlobServiceClient } from '@azure/storage-blob';
import { Storage } from './storage';

export const AzureStorageContainer = async (departmentId, accountName, sasToken) => {
  if(!accountName || !sasToken) return { containerName: '', containerClient: null };

  const blobService = new BlobServiceClient(`https://${accountName}.blob.core.windows.net/?${sasToken}`);
  const containerName = `department-id-${departmentId || 0}`;
  const containerClient = blobService.getContainerClient(containerName);
  const isExists = await containerClient.exists();
  if(!isExists) await containerClient.createIfNotExists({ access: 'container' });

  return { containerName: containerName, containerClient: containerClient };
}

export const AzureMsalCreateConfig = (tenant, app) => {
  if(!tenant || !app) return {};
  let auth = {
    clientId: `${app.client_id}`,
    authority: `${app.authority[0].url}`,
    redirectUri: APP_URL,
    navigateToLoginRequestUrl: false,
  };
  if(tenant.b2c === 'Y'){
    auth.knownAuthorities = [`${app.knownAuthorities}`];
    auth.postLogoutRedirectUri = APP_URL;
  }
  return {
    auth,
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
    system: {},
  };
}
export const AzureMsalGetLocalConfig = () => {
  let tenant = Storage.getItem(`${APP_PREFIX}_MSAL_TENANT`);
  let app = Storage.getItem(`${APP_PREFIX}_MSAL_APP`);
  if(!tenant || !app) return null;
  try {
    tenant = JSON.parse(CryptoJS.AES.decrypt(tenant, TOKEN_KEY).toString(CryptoJS.enc.Utf8));
    app = JSON.parse(CryptoJS.AES.decrypt(app, TOKEN_KEY).toString(CryptoJS.enc.Utf8));
    return AzureMsalCreateConfig(tenant, app);
  } catch(_) {}
  return null;
}
