import { unescape } from 'html-escaper';

export class AlertModel {
  constructor(data={}) {
    this.Loading = data?.Loading? data.Loading: false;
    this.Status = data?.Status? data.Status: false;
    this.Type = data?.Type? data.Type: '';
    this.Message = data?.Message? unescape(data.Message): '';
    this.Errors = data?.Errors && data.Errors.length? data.Errors: [];
  }
}
