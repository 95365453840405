import { createContext, useState, useEffect } from 'react';
import { DepartmentModel, UserModel, UserRoleModel } from '../models';
import { isSafeUrl, getValueOrDefault } from '../helpers/utility';

import CryptoJS from 'crypto-js';
import { apiHeader } from '../helpers/header';
import { APP_PREFIX, TOKEN_KEY, REFRESH_KEY } from '../actions/variables';
import { Storage } from '../helpers/storage';
import { ApiUrls } from '../actions/url.actions';

const AuthContext = createContext({
  status: 'loading',
  accessToken: null,
  refreshToken: null,
  user: new UserModel(),
  permissions: {},
  isSignedIn: false,
  isSuperAdmin: false,
  isAdmin: false,
  isUser: false,
  onSignin: () => {},
  onSignout: () => {},
  onUpdate: () => {},
  onUpdateRole: () => {},
  onUpdateDepartment: () => {},
  onFailedDeviceId: () => {},
});

export const AuthContextProvider = (props) => {
  const [status, setStatus] = useState('loading');
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [user, setUser] = useState(new UserModel());
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const onLoad = async () => {
      let _accessToken = Storage.getItem(`${APP_PREFIX}_ACCESS`);
      let _refreshToken = Storage.getItem(`${APP_PREFIX}_REFRESH`);
      let _user = Storage.getItem(`${APP_PREFIX}_USER`);
      let _permissions = Storage.getItem(`${APP_PREFIX}_PERMISSIONS`);
      
      if(!_accessToken || !_refreshToken || !_user){ onSignout(); return () => {}; }
      try {
        _accessToken = CryptoJS.AES.decrypt(_accessToken, TOKEN_KEY).toString(CryptoJS.enc.Utf8);
        _refreshToken = CryptoJS.AES.decrypt(_refreshToken, REFRESH_KEY).toString(CryptoJS.enc.Utf8);

        _user = CryptoJS.AES.decrypt(_user, TOKEN_KEY).toString(CryptoJS.enc.Utf8);
        _user = new UserModel(_user? JSON.parse(_user): {});

        _permissions = CryptoJS.AES.decrypt(_permissions, TOKEN_KEY).toString(CryptoJS.enc.Utf8);
        _permissions = _permissions? JSON.parse(_permissions): {};
        
        const url = ApiUrls['refresh'];
        if(!isSafeUrl(url)){ onSignout(); return () => {}; }

        const _fetch = await fetch(url, {
          method: 'PATCH',
          headers: apiHeader(_accessToken),
        });
        const _data = await _fetch.json();
        if(_fetch.ok && _fetch.status === 200 && _data){
          setStatus('authenticated');
          setAccessToken(_accessToken);
          setRefreshToken(_refreshToken);
          setUser(_user);
          setPermissions(_permissions);
          return () => {};
        }
      } catch(_) { console.log(_) }
      
      onSignout(); return () => {};
    }
    onLoad();
  }, []);
  
  const onSignin = ({ aToken, rToken, u, p, tenant=null, app=null }) => {
    try {
      if(aToken && rToken && u){
        let _user = new UserModel(u);
        if(_user.isSignedIn()){
          let _accessToken = CryptoJS.AES.encrypt(aToken, TOKEN_KEY).toString();
          let _refreshToken = CryptoJS.AES.encrypt(rToken, REFRESH_KEY).toString();
          let _permissions = CryptoJS.AES.encrypt(JSON.stringify(getValueOrDefault(p, {})), TOKEN_KEY).toString();
          
          Storage.setItem(
            `${APP_PREFIX}_USER`,
            CryptoJS.AES.encrypt(JSON.stringify(_user), TOKEN_KEY).toString(),
          );
          Storage.setItem(`${APP_PREFIX}_ACCESS`, _accessToken);
          Storage.setItem(`${APP_PREFIX}_REFRESH`, _refreshToken);
          Storage.setItem(`${APP_PREFIX}_PERMISSIONS`, _permissions);

          if(tenant && app){
            Storage.setItem(`${APP_PREFIX}_MSAL_TENANT`, 
              CryptoJS.AES.encrypt(JSON.stringify(tenant), TOKEN_KEY).toString());
            Storage.setItem(`${APP_PREFIX}_MSAL_APP`, 
              CryptoJS.AES.encrypt(JSON.stringify(app), TOKEN_KEY).toString());
          }

          setStatus('authenticated');
          setAccessToken(aToken);
          setRefreshToken(rToken);
          setUser(_user);
          setPermissions(getValueOrDefault(p, {}));
          return true;
        }
      }
    } catch(_) {}
    
    onSignout();
    return false;
  }
  const onSignout = () => {
    setStatus('unauthenticated');
    setAccessToken(null);
    setRefreshToken(null);
    setUser(new UserModel());
    Storage.removeItem(`${APP_PREFIX}_ACCESS`);
    Storage.removeItem(`${APP_PREFIX}_REFRESH`);
    Storage.removeItem(`${APP_PREFIX}_USER`);
    Storage.removeItem(`${APP_PREFIX}_PERMISSIONS`);
    Storage.removeItem(`${APP_PREFIX}_MSAL_TENANT`);
    Storage.removeItem(`${APP_PREFIX}_MSAL_APP`);
    return true;
  }

  const onUpdate = (_user=new UserModel()) => {
    if(status === 'authenticated' && user.isSignedIn()){
      const _newUser = {
        ...user,
        Prefix: getValueOrDefault(_user?.Prefix, user.Prefix),
        FirstName: getValueOrDefault(_user?.FirstName, user.FirstName),
        LastName: getValueOrDefault(_user?.LastName, user.LastName),
        Username: getValueOrDefault(_user?.Username, user.Username),
      };
      setUser(new UserModel(_newUser));
      Storage.setItem(
        `${APP_PREFIX}_USER`,
        CryptoJS.AES.encrypt(JSON.stringify(_newUser), TOKEN_KEY).toString(),
      );
    }
    return true;
  }
  const onUpdateRole = (_role=new UserRoleModel()) => {
    if(status === 'authenticated' && user.isSignedIn() && _role?.Id){
      const _newUser = {
        ...user,
        Role: new UserRoleModel(_role),
      };
      setUser(new UserModel(_newUser));
      Storage.setItem(
        `${APP_PREFIX}_USER`,
        CryptoJS.AES.encrypt(JSON.stringify(_newUser), TOKEN_KEY).toString(),
      );

      const _newPermissions = { ..._role.Permissions };
      setPermissions(_newPermissions);
      Storage.setItem(
        `${APP_PREFIX}_PERMISSIONS`,
        CryptoJS.AES.encrypt(JSON.stringify(_newPermissions), TOKEN_KEY).toString(),
      );
    }
    return true;
  }
  const onUpdateDepartment = (_dept=new DepartmentModel()) => {
    if(status === 'authenticated' && user.isSignedIn() && _dept?.Id){
      const _newUser = {
        ...user,
        Department: new DepartmentModel(_dept),
      };
      setUser(new UserModel(_newUser));
      Storage.setItem(
        `${APP_PREFIX}_USER`,
        CryptoJS.AES.encrypt(JSON.stringify(_newUser), TOKEN_KEY).toString(),
      );
    }
    return true;
  }
  
  const onFailedDeviceId = () => {
    setStatus('failedDeviceId');
  }

  return (
    <AuthContext.Provider 
      value={{
        status: status,
        accessToken: accessToken,
        refreshToken: refreshToken,
        user: user,
        permissions: permissions,
        isSignedIn: user.isSignedIn(),
        onSignin: onSignin,
        onSignout: onSignout,
        onUpdate: onUpdate,
        onUpdateRole: onUpdateRole,
        onUpdateDepartment: onUpdateDepartment,
        onFailedDeviceId :onFailedDeviceId,
      }} 
    >
      {props.children}
    </AuthContext.Provider>
  );
};


export default AuthContext;