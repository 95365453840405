import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import MediaUpload from '../MediaUpload';

import { connect } from 'react-redux';

function MobileNavInternal(props) {
  const { permissions } = useContext(AuthContext);
  return (<>
    <div className="mobile-uploader-backdrop border-top-1 bcolor-sgray"></div>
    <nav className="mobile-nav border-top-1 bcolor-sgray">
      <div className="items">
        <Link to="/internal/medias" 
          className={`item ${props.menuActive===2? 'active': ''}`} 
        >
          {props.menuActive===2 
            ? <FolderIcon sx={{ fontSize: '1.75rem' }} /> 
            : <FolderOutlinedIcon sx={{ fontSize: '1.75rem' }} />}
        </Link>
        <Link to="/internal/expirations" 
          className={`item ml-auto ${props.menuActive===6? 'active': ''} 
            ${permissions.PageReport !== 1? 'disabled': ''}`} 
        >
          {props.menuActive===6 
            ? <AccessTimeFilledIcon sx={{ fontSize: '1.75rem' }} /> 
            : <AccessTimeIcon sx={{ fontSize: '1.75rem' }} />}
        </Link>
        <div className="uploader">
          <Button disabled={permissions.MediaCreate !== 1} 
            variant="contained" color="primary" size="large" 
            className={`pos-relative ovf-hidden`} disableElevation 
            style={{ background: permissions.MediaCreate !== 1? 'rgba(255,255,255,.4)': '' }} 
          >
            <CameraAltOutlinedIcon fontSize="large" />
            <MediaUpload onMobileNav={true} />
          </Button>
        </div>
      </div>
    </nav>
    <div className="mobile-nav-spacer"></div>
  </>);
}

MobileNavInternal.propTypes = {};
const mapStateToProps = (state) => ({
  menuActive: state.app.menuActive,
});
export default connect(mapStateToProps, {})(MobileNavInternal);