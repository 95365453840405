import { getValueOrDefault, displayStorage as _displayStorage } from '../helpers/utility';

export class FileModel {
  constructor(data={}) {
    this.Id = data.Id? data.Id: null;

    this.FileName = getValueOrDefault(data?.FileName);
    this.OriginalName = getValueOrDefault(data?.OriginalName);
    this.Mimetype = getValueOrDefault(data?.Mimetype);
    this.Size = Number(getValueOrDefault(data?.Size, 0));
    this.Path = getValueOrDefault(data?.Path, '/assets/img/default/img.jpg');
  }

  isValid(){
    return this.Id? true: false;
  }

  displaySize(){ return _displayStorage(this.Size); }
}