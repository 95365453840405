import { useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { getValueOrDefault } from '../../helpers/utility';

import { Button, IconButton, MenuItem } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareIcon from '@mui/icons-material/Share';

import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { updateAppInit } from '../../actions/app.actions';
import { dispatchSignout } from '../../actions/user.actions';

const menuDisabled = (val) => val? true: false;
const menuDisabledClass = (val) => val? 'disabled': '';
const menuActiveClass = (val, active) => val === active? 'bg-p': '';

function TopnavInternal({ test=false, ...props }) {
  const dispatch = useDispatch();
  const { user, permissions } = useContext(AuthContext);

  const onSignoutProcess = async (e) => {
    e.preventDefault();
    dispatchSignout(dispatch);
  }

  const navigate = useNavigate();
  const onGoBack = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const menu = [
    { active: 2, title: 'การจัดการมีเดีย', to: '/internal/medias', icon: <FolderOutlinedIcon /> },
    { active: 6, title: 'มีเดียใกล้หมดอายุ', to: '/internal/expirations', icon: <AccessTimeIcon />, disabled: permissions.PageMediaExpiring !== 1 },
    { active: 3, title: 'รายงาน', to: '/internal/reports', icon: <InsertChartOutlinedIcon />, disabled: permissions.PageReport !== 1 },
    { active: 4, title: 'การตั้งค่า', to: '/internal/settings', icon: <SettingsOutlinedIcon />, disabled: permissions.PageSettings !== 1 },
  ];
  
  const location = useLocation();
  /* eslint-disable */
  useEffect(() => {
    if(!getValueOrDefault(location?.pathname).includes('/internal/medias')){
      updateAppInit(true)(dispatch);
    }
  }, [location]);
  /* eslint-enable */

  return (<>
    <nav className="topnav bshadow">
      <div className="container">
        <div className="topnav-wrapper">
          <div className="options show-tablet" style={{ width: '5.125rem' }}>
            <div className={`option ${props.menuActive === 1? 'op-0 pe-none': ''}`}>
              <IconButton onClick={onGoBack} color="white" size="small" 
                style={{ background: 'rgba(255,255,255,.1)' }} 
              >
                <ArrowBackIosRoundedIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <Link to="/internal" className="logo">
            <div className="wrapper">
              <img className="ptt hide-tablet" src="/assets/logo.png" alt="Logo" />
              <img className="ptt show-tablet" src="/assets/logo-white.png" alt="Logo" />
              <div className="slogan">
                <div className="title">GSP</div>
                <div className="desc">PHOTO HUB</div>
              </div>
              <img className="hero hide-tablet" src="/assets/hero-01.png" alt="Hero" />
            </div>
          </Link>
          <div className="menu-container">
            {menu.map((d, i) => (
              <div key={`menu_${i}`} className="menu">
                <Button component={Link} to={d.to} disableElevation 
                  color={props.menuActive === d.active? 'primary': 'white'} 
                  className={`${menuActiveClass(d.active, props.menuActive)} ${menuDisabledClass(d.disabled)}`} 
                  variant="contained" disabled={menuDisabled(d.disabled)} startIcon={d.icon} 
                >
                  <p className="fw-500">{d.title}</p>
                </Button>
              </div>
            ))}
          </div>
          <div className="options">
            <div className="option ml-2 hide-tablet">
              <IconButton component={Link} to="/internal/user/profile" 
                color="default" className="d-block bradius soft-p p-1" 
              >
                <div className="avatar" style={{ margin: 'auto' }}>
                  <div className="img-bg bradius-round" 
                    style={{ backgroundImage: `url('${getValueOrDefault(user.Avatar?.Path, '/assets/img/default/avatar.jpg')}')` }} 
                  ></div>
                </div>
                <p className="avatar-title xxxs fw-400 ws-nowrap color-white mt-1">
                  {user.displayRole()}
                </p>
              </IconButton>
              <div className="dropdown bradius bshadow border-1 bcolor-sgray">
                <div className="pt-1 pb-2 pl-4 pr-4 mb-2 border-bottom-1 bcolor-sgray">
                  <p className="fw-500 ws-nowrap">
                    {user.displayName()}
                  </p>
                  <p className="xxs fw-400 op-70 ws-nowrap">
                    <span className="fw-500">บทบาท :</span> {user.displayRole()}
                  </p>
                </div>
                <MenuItem className="soft-p pr-5" component={Link} to="/internal/user/profile">
                  <PersonOutlineOutlinedIcon fontSize="small" className="mr-3" /> ข้อมูลส่วนตัว
                </MenuItem>
                <MenuItem className="soft-p pr-5" component={Link} to="/internal/user/role">
                  <ManageAccountsOutlinedIcon fontSize="small" className="mr-3" /> เลือกบทบาท
                </MenuItem>
                <MenuItem className="soft-p pr-5" component={Link} to="/internal/user/shared-medias">
                  <ShareIcon fontSize="small" className="mr-3" /> มีเดียที่แชร์ให้คุณ
                </MenuItem>
                <MenuItem className="soft-p pr-5" onClick={onSignoutProcess}>
                  <LogoutOutlinedIcon fontSize="small" className="mr-3" /> ออกจากระบบ
                </MenuItem>
              </div>
            </div>
            <div className="option show-tablet">
              <IconButton component={Link} to="/internal/user" 
                color="default" className="d-block bradius soft-p p-1" 
              >
                <div className="avatar" style={{ margin: 'auto' }}>
                  <div className="img-bg bradius-round" 
                    style={{ backgroundImage: `url('${getValueOrDefault(user.Avatar.Path, '/assets/img/default/avatar.jpg')}')` }} 
                  ></div>
                </div>
                <p className="avatar-title xxxs fw-400 ws-nowrap color-white mt-1">
                  {user.displayRole()}
                </p>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div className="topnav-spacer"></div>
    <div className="topnav-pattern" style={{ backgroundImage: `url('/assets/img/bg/pattern-03.jpg')` }}></div>
  </>);
}

TopnavInternal.propTypes = {
  test: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  menuActive: state.app.menuActive,
});
export default connect(mapStateToProps, {})(TopnavInternal);