import { useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { checkDeviceId } from '../../actions/app.actions';
import { isMobile, isTablet, isAndroid } from 'react-device-detect';

import { useMsal } from '@azure/msal-react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { AzureMsalGetLocalConfig } from '../../helpers/azure';
import { userSignout } from '../../actions/user.actions';

import { FORCE_SIGNOUT } from '../../actions/types';
import { APP_URL } from '../../actions/variables';

const MsalSignoutAlert = ({ test=false, forceSignout=0 }) => {
  const dispatch = useDispatch();
  const { accessToken, onSignout } = useContext(AuthContext);
  const { instance } = useMsal();
  const onSignoutProcess = async (type=1) => {
    const res = await userSignout(accessToken, test, type)(dispatch);
    if(res){
      instance.logoutRedirect({ postLogoutRedirectUri: APP_URL, onRedirectNavigate: (_) => false });
      onSignout();
    }
  }
  /* eslint-disable */
  useEffect(() => {
    const onQuit = async () => {
      if(forceSignout > 0){
        await onSignoutProcess(forceSignout === 2? 2: 1);
        dispatch({ type: FORCE_SIGNOUT, payload: 0 });
      }
    }
    onQuit();
  }, [forceSignout]);
  /* eslint-enable */
  return (<></>);
}

function AlertPopup({ test=false, ...props }) {
  const { status, onFailedDeviceId } = useContext(AuthContext);
  const msalConfig = AzureMsalGetLocalConfig();
  
  /* eslint-disable */
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const onLoad = async () => {
      if(status === 'loading' || status === 'failedDeviceId') return;
      if(!isMobile && !isTablet && !isAndroid ) return;
      const res = await checkDeviceId(searchParams);
      if(!test && !res) onFailedDeviceId();
      return;
    }
    onLoad();
  }, [status]);
  /* eslint-enable */

  return (<>
    <div className={`global-loader ${props.alert?.Loading? 'active': ''}`}>
      <div className="loader">
        <div className="loader-wrapper">
          <div className="arrow prev">‹</div>
          <div className="text">Loading</div>
          <div className="arrow next">‹</div>
        </div>
      </div>
    </div>
    {msalConfig? (
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        <MsalSignoutAlert test={test} forceSignout={props.forceSignout} />
      </MsalProvider>
    ): (<></>)}
  </>);
}

AlertPopup.propTypes = {};
const mapStateToProps = (state) => ({
	alert: state.alert,
  forceSignout: state.app.forceSignout,
});
export default connect(mapStateToProps, {})(AlertPopup);