import { useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { redirectToUrl } from '../../helpers/utility';
import { connect } from 'react-redux';


function RedirectPage({ test=false, ...props }) {
  const { status } = useContext(AuthContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  /* eslint-disable */
  useEffect(() => {
    const _redirect = redirectToUrl(status, searchParams.get('url'));
    if(_redirect) navigate(_redirect);
  }, [status]);
  /* eslint-enable */

  return (<></>);
};

RedirectPage.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(RedirectPage);