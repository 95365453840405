import { AlertModel } from '../models';
import { ALERT_LOADING } from '../actions/types';

const initialState = new AlertModel();

const alertReducer = (state = initialState, action) => {
  switch(action.type) {

    case ALERT_LOADING:
      return { ...state, Loading: action.payload };
      
    default:
      return state;
  }
};

export default alertReducer;