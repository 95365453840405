import { getValueOrDefault } from '../helpers/utility';
import { unescape } from 'html-escaper';

/*
  Role : String
    - Super Admin
    - Admin
    - User
*/

export class UserRoleModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);
    this.Name = data?.Name? unescape(data.Name): null;
    this.Role = data?.Role? unescape(data.Role): null;
    this.Permissions = JSON.parse(typeof data?.Permissions === 'string'? data?.Permissions: '{}');
  }

  isValid() {
    return this.Id? true: false;
  }
  
  displayName() {
    if(this.isValid()) return this.Name;
    else return '';
  }
}
