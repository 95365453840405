import { UserModel, DepartmentModel } from './';
import {
  ifNull, ifNullNumber, ifNullData, getValueOrDefault,
  displayStorage as _displayStorage,
} from '../helpers/utility';

/*
  ShareType : Number
    1 = Public
    2 = Share & for department
    3 = For department
    4 = Private
*/

const _displayShareTypeSelf = (shareType=0, sharedCount=0) => {
  if(shareType === 5) return (<span className="ss-tag sm bg-warning">ส่วนตัว/แชร์</span>);
  if(shareType === 4) return (<span className="ss-tag sm bg-success">ส่วนตัว</span>);
  if(shareType === 3 && sharedCount) return (<span className="ss-tag sm bg-info">ส่วนงาน/แชร์</span>);
  if(shareType === 3) return (<span className="ss-tag sm bg-info">ส่วนงาน</span>);
  if(shareType === 2) return (<span className="ss-tag sm bg-warning">เปิดแชร์</span>);
  return (<span className="ss-tag sm bg-danger">สาธารณะ</span>);
}

export class MediaModel {
  constructor(data={}) {
    this.Id = data.Id? data.Id: null;

    this.Department = new DepartmentModel(ifNull(data?.Department, {}));

    this.IsFolder = data.IsFolder? 1: 0;
    this.FileCount = ifNullNumber(data?.FileCount);
    this.ShareCount = ifNullNumber(data?.ShareCount);

    this.Folders = data?.Folders && data.Folders.length? data.Folders: [];
    this.Expanded = data.Expanded? 1: 0;

    this.FileName = ifNull(data?.FileName, '');
    this.OriginalName = ifNull(data?.OriginalName, '');
    this.Mimetype = ifNull(data?.Mimetype, '');
    this.Size = ifNullNumber(data?.Size);
    this.Preview = getValueOrDefault(data?.Preview, null);
    this.Path = getValueOrDefault(data?.Path, '/assets/img/default/img.jpg');
    this.Bytes = data?.Bytes || null;
    
    this.Status = [-1,0,1].indexOf(Number(data?.Status)) > -1? Number(data.Status): 0;
    this.ShareType = [1,2,3,4].indexOf(Number(data.ShareType)) > -1? Number(data.ShareType): 2;
    if([2,4].indexOf(this.ShareType) > -1 && this.ShareCount > 0) this.ShareType = 5;
    this.ExpiredAt = ifNullData(data.ExpiredAt);

    this.IsPinned = data.IsPinned? 1: 0;

    this.InFolderId = ifNull(data.InFolderId);
    this.InFolder = data?.InFolder? {
      Id: ifNull(data.InFolder?.Id),
      FileName: ifNull(data.InFolder?.FileName, ''),
      OriginalName: ifNull(data?.InFolder?.OriginalName, ''),
    }: null;

    this.Drawing = ifNull(data?.Drawing);

    this.CreatedBy = new UserModel(ifNull(data?.CreatedBy, {}));
    this.CreatedAt = ifNullData(data.CreatedAt);
    this.UpdatedBy = new UserModel(ifNull(data?.UpdatedBy, {}));
    this.UpdatedAt = ifNullData(data.UpdatedAt);
  }

  isValid(){
    return this.Id? true: false;
  }

  displayType(){
    if(this.isValid()){
      if(this.IsFolder) return 'โฟลเดอร์';
      else if(this.Mimetype){
        if(this.Mimetype.includes('image')) return 'รูปภาพ';
        else if(this.Mimetype.includes('video')) return 'วิดีโอ';
        else return 'ไฟล์';
      }
    }
    return '';
  }
  displaySize(){ return _displayStorage(this.Size); }
  displayShareType(user={}){
    if(!this.isValid()) return '';
    return _displayShareTypeSelf(this.ShareType, this.ShareCount);
  }

  isImage(editable=false) {
    if(!editable) return this.isValid() && this.Mimetype.includes('image');
    return this.isValid() && ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].indexOf(this.Mimetype) > -1;
  }
  isVideo() {
    return this.isValid() && this.Mimetype.includes('video');
  }
}