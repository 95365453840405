import { nextDays } from '../helpers/utility';
import {
  EmailTemplateModel, EmailNotiModel,
  DepartmentModel, UserRoleModel, UserModel,
  MediaSettingModel, MediaModel,
} from '../models';


export const MOCK_EMAIL_TEMPLATES = () => [
  new EmailTemplateModel({
    Id: '1', Type: 1,
    SenderEmail: 'info@ptt-gsp.co.th',
    SenderName: 'PTT GSP Photo Hub',
  }),
  new EmailTemplateModel({
    Id: '2', Type: 2,
    SenderEmail: 'info@ptt-gsp.co.th',
    SenderName: 'PTT GSP Photo Hub',
  }),
  new EmailTemplateModel({
    Id: '3', Type: 3,
    SenderEmail: 'info@ptt-gsp.co.th',
    SenderName: 'PTT GSP Photo Hub',
  }),
];

export const MOCK_EMAIL_NOTIS = () => [
  new EmailNotiModel({ Id: '1', Type: 1, DayIndex: 1, Hour: 9, Duration: 30, Status: 1 }),
  new EmailNotiModel({ Id: '2', Type: 1, DayIndex: 1, Hour: 9, Duration: 15, Status: 1 }),
  new EmailNotiModel({ Id: '3', Type: 1, DayIndex: 1, Hour: 9, Duration: 7, Status: 1 }),
  new EmailNotiModel({ Id: '4', Type: 2, DayIndex: 1, Hour: 9, Status: 1 }),
  new EmailNotiModel({ Id: '5', Type: 2, DayIndex: 4, Hour: 9, Status: 0 }),
  new EmailNotiModel({ Id: '6', Type: 3, DayIndex: 1, Hour: 9, Status: 1 }),
  new EmailNotiModel({ Id: '7', Type: 3, DayIndex: 2, Hour: 9, Status: 0 }),
  new EmailNotiModel({ Id: '8', Type: 3, DayIndex: 3, Hour: 9, Status: 0 }),
  new EmailNotiModel({ Id: '9', Type: 3, DayIndex: 4, Hour: 9, Status: 0 }),
  new EmailNotiModel({ Id: '10', Type: 3, DayIndex: 5, Hour: 9, Status: 0 }),
];


const DEPARTMENTS = [ ...Array(10).keys() ].map(i => {
  const _storage = 1024**3 * (i + 1);
  return new DepartmentModel({
    Id: `${i+1}`,
    Name: `ส่วนการทำงานที่ ${i+1}`,
    Status: 1,
    Storage: _storage,
    MinStorage: 0.5 * 1024**3,
    UsedStorage: Math.round(_storage),
  });
});
export const MOCK_DEPARTMENTS = () => DEPARTMENTS;


const USER_ROLES = [
  new UserRoleModel({ Id: '1', Name: 'Super Admin', Role: 'Super Admin' }),
  new UserRoleModel({ Id: '2', Name: 'Admin', Role: 'Admin' }),
  new UserRoleModel({ Id: '3', Name: 'User', Role: 'User' }),
];
export const MOCK_USER_ROLES = () => USER_ROLES;

const USER = new UserModel({
  Id: '1',
  Department: DEPARTMENTS[0],
  Role: USER_ROLES[0],
  Title: 'Software Engineer',
  FirstName: 'Super',
  LastName: 'Admin',
  Username: 'SuperAdmin',
  Email: 'super-admin@gmail.com',
  Avatar: {
    Id: '1',
    OriginalName: 'avatar.jpg',
    Mimetype: 'image/jpeg',
    FileName: 'avatar.jpg',
    Size: 1024,
    Path: '/assets/img/default/avatar.jpg',
  },
  Status: 1,
});
export const MOCK_USER = () => USER;


export const MOCK_REPORT_TOTAL = () => ({ users: 5, images: 116, videos: 24, usage: 32.84 });
export const MOCK_REPORT_USAGE = () => {
  let _res = [];
  const _now = new Date();
  const _year = _now.getFullYear();
  const _month = _now.getMonth() + 1;
  const _date = _now.getDate();
  const _days = new Date(_year, _month, 0).getDate();
  for(let i=1; i<=_days; ++i){
    _res.push({
      year: _year,
      month: _month,
      day: i,
      total: i <= _date? 4: 0,
    });
  }
  return _res;
};


export const MOCK_USERS = () => {
  return [ ...Array(250).keys() ].map(i => new UserModel({
    Id: `${i+1}`,
    Department: DEPARTMENTS[0],
    Role: USER_ROLES[0],
    Title: `Job title ${i+1}`,
    FirstName: 'General',
    LastName: `User ${i+1}`,
    Username: 'SuperAdmin',
    Email: `email${i+1}@gmail.com`,
    Avatar: {
      Id: '1',
      OriginalName: 'avatar.jpg',
      Mimetype: 'image/jpeg',
      FileName: 'avatar.jpg',
      Size: 1024,
      Path: '/assets/img/default/avatar.jpg',
    },
    Status: 1,
  }));
};


export const MOCK_MEDIA_SETTINGS = () => [
  new MediaSettingModel({
    Id: '1', Name: 'รูปภาพ',
    Type: 'image/png,image/jpeg,image/jpg,image/webp,image/gif,image/tiff,image/svg+xml',
    Limit: 10 * 1024**2, Status: 1,
  }),
  new MediaSettingModel({
    Id: '2', Name: 'วิดีโอ',
    Type: 'video/mp4,video/quicktime,video/mov,video/avi,video/wmv',
    Limit: 25 * 1024**2, Status: 1,
  }),
];

export const MOCK_FOLDERS = (len=2) => {
  return [ ...Array(len).keys() ].map(i => new MediaModel({
    Id: `${i+1}`,
    Department: DEPARTMENTS[0],
    IsFolder: 1,
    FileName: `Folder ${i+1}`,
    OriginalName: `Folder ${i+1}`,
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  }));
};
export const MOCK_MEDIAS = () => {
  return [ ...Array(20).keys() ].map(i => {
    if(i < 2){
      return new MediaModel({
        Id: `${i+1}`,
        Department: DEPARTMENTS[0],
        IsFolder: 1,
        FileName: `Company Folder ${i+1}`,
        OriginalName: `Company Folder ${i+1}`,
        FileCount: Math.floor(10),
        Status: 1,
        ShareType: 3,
        ExpiredAt: nextDays(30),
        CreatedBy: USER,
        CreatedAt: new Date(),
        UpdatedBy: USER,
        UpdatedAt: new Date(),
      });
    }else if(i < 5){
      return new MediaModel({
        Id: `${i+1}`,
        Department: DEPARTMENTS[0],
        FileName: `General Video ${i+1}`,
        OriginalName: `media-${i+1}.jpg`,
        Mimetype: 'video/mp4',
        Size: 10*1024**2,
        Path: `/assets/video/01.mp4`,
        Status: 1,
        ShareType: 3,
        ExpiredAt: nextDays(30),
        CreatedBy: USER,
        CreatedAt: new Date(),
        UpdatedBy: USER,
        UpdatedAt: new Date(),
        InFolderId: '1',
        InFolder: {
          Id: `${i+1}`,
          FileName: `Company Folder ${i+1}`,
          OriginalName: `Company Folder ${i+1}`,
        },
      });
    }else{
      return new MediaModel({
        Id: `${i+1}`,
        Department: DEPARTMENTS[0],
        FileName: `General Image ${i+1}`,
        OriginalName: `media-${i+1}.jpg`,
        Mimetype: 'image/jpeg',
        Size: 3*1024**2,
        Path: `/assets/img/bg/01.jpg`,
        Status: 1,
        ShareType: 3,
        ExpiredAt: nextDays(30),
        CreatedBy: USER,
        CreatedAt: new Date(),
        UpdatedBy: USER,
        UpdatedAt: new Date(),
        InFolderId: '1',
        InFolder: {
          Id: `${i+1}`,
          FileName: `Company Folder ${i+1}`,
          OriginalName: `Company Folder ${i+1}`,
        },
      });
    }
  });
};
