import { useContext, useEffect } from 'react';
import AuthContext from '../../context/AuthContext';

import TopnavInternal from '../TopnavInternal';
import Footer from '../Footer';
import MobileNavInternal from '../MobileNavInternal';

import { connect, useDispatch } from 'react-redux';
import { updateSettings, updateMediaSettings, updateScreenWidth } from '../../actions/app.actions';

function LayoutInternal(props) {
  const { isSignedIn, accessToken } = useContext(AuthContext);

  const dispatch = useDispatch();
  /* eslint-disable */
	useEffect(() => {
		const handleResize = () => updateScreenWidth(window.innerWidth)(dispatch);
    handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
  }, []);
	useEffect(() => {
    const onLoad = async () => {
      if(!accessToken) return;
      await updateSettings(accessToken)(dispatch);
      await updateMediaSettings(accessToken)(dispatch);
      return;
    };
    onLoad();
  }, [accessToken]);
  /* eslint-enable */

  return !isSignedIn? (<></>): (<>
    <TopnavInternal />
    <div className="app-container">
      {props.children}
      <Footer />
    </div>
    <MobileNavInternal />
  </>);
}

LayoutInternal.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(LayoutInternal);