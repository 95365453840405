import { API_URL, APP_URL } from '../actions/variables';
import { ApiUrls } from '../actions/url.actions';
import Cookies from 'universal-cookie';

const _apiHeader = (accessToken, forgery) => {
  let headers = { 'Content-Type': 'application/json' };
  if(accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if(forgery) headers['X-CSRF-TOKEN'] = forgery;
  return headers;
}
export const apiHeader = _apiHeader;
export const apiHeaderFormData = (accessToken, forgery) => {
  let headers = {};
  if(accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if(forgery) headers['X-CSRF-TOKEN'] = forgery;
  return headers;
}

const _getDomain = () => {
  if(!APP_URL) return 'localhost';
  const _urls1 = APP_URL.replace('http://', '').replace('https://', '').split('/');
  const _urls2 = _urls1[0].split(':')[0];
  const _urls3 = _urls2.split('.');
  if(_urls3.length < 2) return _urls3[0];
  return `${_urls3[_urls3.length-2]}.${_urls3[_urls3.length-1]}`;
}
export const getForgery = async (accessToken='') => {
  try {
    const url = ApiUrls['get-forgery'];
    if(!url) return '';

    const _fetch = await fetch(url, {
      method: 'GET',
      headers: _apiHeader(accessToken),
    });
    const _data = await _fetch.json();
    const cookies = new Cookies();
    if(_data?.Result?.CookieToken){
      cookies.set('X-CSRF-TOKEN', _data.Result.CookieToken, { path: '/', domain: _getDomain() });
    }
    return _data?.Result?.RequestToken;
  } catch(_) { console.log(`Error GET - ${API_URL}api/get-forgery`); console.log(_); }
  return '';
}
