import { UserRoleModel, FileModel, DepartmentModel } from '.';
import { getValueOrDefault } from '../helpers/utility';
import { unescape } from 'html-escaper';

export class UserModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);

    this.EmployeeId = getValueOrDefault(data?.EmployeeId, null);
    this.DepartmentCode = getValueOrDefault(data?.DepartmentCode, null);
    this.Department = new DepartmentModel(getValueOrDefault(data?.Department, {}));
    this.Role = new UserRoleModel(getValueOrDefault(data?.Role, {}));
    
    this.Title = data?.Title? unescape(data.Title): null;
    this.Prefix = data?.Prefix? unescape(data.Prefix): null;
    this.FirstName = data?.FirstName? unescape(data.FirstName): null;
    this.LastName = data?.LastName? unescape(data.LastName): null;
    this.Username = data?.Username? data.Username: null;
    this.Email = getValueOrDefault(data?.Email, null);
    this.Avatar = new FileModel(
      data?.Avatar && typeof data.Avatar === 'object'? data.Avatar 
        : { Path: getValueOrDefault(data?.Avatar, '/assets/img/default/avatar.jpg') }
    );

    this.Status = data?.Status? Number(data.Status): 0;
  }

  isValid() { return this.Id? true: false; }

  displayName() {
    if(this.FirstName || this.LastName){
      return `${getValueOrDefault(this.Prefix)} ${getValueOrDefault(this.FirstName)} ${getValueOrDefault(this.LastName)}`.trim();
    }else if(this.Username){
      return this.Username;
    }else{
      return '';
    }
  }
  displayRole() {
    return this.Role.displayName();
  }
  displayStatus() {
    if(this.isValid()){
      if(this.Status === 1) return (<span className="ss-tag bg-success">เปิดใช้งาน</span>);
      else return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }else{
      return (<span className="ss-tag bg-warning">ปิดใช้งาน</span>);
    }
  }

  isSignedIn() { return this.Id && this.Status === 1 && this.Role.isValid()? true: false; }

  isSuperAdmin() { return this.isSignedIn() && ['Super Admin'].indexOf(this.Role.Role) > -1? true: false; }
  isAdmin() { return this.isSignedIn() && ['Admin', 'Super Admin'].indexOf(this.Role.Role) > -1? true: false; }
  isUser() { return this.isSignedIn() && ['User'].indexOf(this.Role.Role) > -1? true: false; }
}
