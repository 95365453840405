export class PaginateModel {
  constructor(data={}) {
    this.Page = data?.Page? Number(data.Page): 1;
    this.Pp = data?.Pp? Number(data.Pp): 10;
    this.Total = data?.Total? Number(data.Total): 0;
  }

  totalPages() {
    return this.Pp > 0? Math.ceil(this.Total / this.Pp): 0;
  }

  start() { return (this.Page - 1) * this.Pp; }
  end() { return this.Page * this.Pp; }

  anchorId() {
    return (this.Page - 1) * this.Pp + 1;
  }
}
