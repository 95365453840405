import { getValueOrDefault } from '../helpers/utility';
import { unescape } from 'html-escaper';
import { UserModel } from './user.model';

/*
  Type : Number
    1 = อีเมลแจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก N วัน
    2 = อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด
    3 = อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด
*/

export class EmailTemplateModel {
  constructor(data={}) {
    this.Id = getValueOrDefault(data?.Id, null);

    this.Type = data?.Type? Number(data.Type): 0;

    this.Html = data?.Html? unescape(data.Html): null;

    this.SenderEmail = data?.SenderEmail? unescape(data.SenderEmail): null;
    this.SenderName = data?.SenderName? unescape(data.SenderName): null;

    this.SendType = data?.SendType && data.SendType.length 
      ? data.SendType.filter(d => d).map(d => Number(d)): [];
    this.SendTo = data?.SendTo && data.SendTo.length 
      ? data.SendTo.filter(d => d).map(d => `${d}`): [];

    this.Users = data?.Users && data.Users.length 
      ? data.Users.map(d => new UserModel(d)).map(d => d): [];
  }

  isValid() {
    return this.Id? true: false;
  }

  displayName() {
    if(this.isValid()){
      if(this.Type === 1) return 'อีเมลแจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก N วัน';
      else if(this.Type === 2) return 'อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด';
      else if(this.Type === 3) return 'อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด';
    }
    return '';
  }
  displayNameStatic(type=1) {
    if(type === 1) return 'อีเมลแจ้งเตือนเมื่อมีเดียใกล้หมดอายุภายในอีก N วัน';
    else if(type === 2) return 'อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของส่วนงานใกล้หมด';
    else if(type === 3) return 'อีเมลแจ้งเตือนเมื่อพื้นที่ความจำของระบบใกล้หมด';
    else return '';
  }
}
