import momentTZ from 'moment-timezone';


export const isTableLoading = (loading, tableData) => {
  return loading || !tableData || !tableData.length;
}

export const ifNull = (_val, _default=null) => _val ?? _default;
export const ifNullNumber = (_val, _default=0) => _val? Number(_val): _default;
export const ifNullData = (_val, _default=null) => _val? new Date(_val): _default;

export const mimetypePreview = (mimetype='', tempPath='') => {
  if(mimetype){
    if(['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/tiff', 'image/svg+xml', 'image/gif'].indexOf(mimetype) > -1){
      return tempPath? tempPath: '/img/file/img.png';
    }else if(['text/csv'].indexOf(mimetype) > -1){
      return '/img/file/csv.png';
    }else if(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(mimetype) > -1){
      return '/img/file/xls.png';
    }else if(['application/pdf'].indexOf(mimetype) > -1){
      return '/img/file/pdf.png';
    }else if(['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(mimetype) > -1){
      return '/img/file/doc.png';
    }else if(['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].indexOf(mimetype) > -1){
      return '/img/file/ppt.png';
    }else if(['application/zip', 'application/x-zip-compressed', 'application/x-zip'].indexOf(mimetype) > -1){
      return '/img/file/zip.png';
    }else if(['video/mp4', 'video/quicktime', 'video/mov', 'video/avi', 'video/wmv'].indexOf(mimetype) > -1){
      return '/img/file/video.png';
    }else if(['audio/mpeg', 'audio/mp3'].indexOf(mimetype) > -1){
      return '/img/file/audio.png';
    }
  }
  return '/img/file/unknown.png';
}

export const formatNumber = (value, digits=2, trimDigits=false) => {
  let val = (value / 1).toFixed(digits);
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if(digits < 1 || !trimDigits) return val;
  else{
    let replacer = (0).toFixed(digits).replace('0.', '.');
    return val.replace(replacer, '');
  }
}

const _formatDate = (value=null, format='DD/MM/YYYY', thai=false) => {
  let string;
  if(!value) string = momentTZ(new Date()).tz('Asia/Bangkok').format(format);
  else string = momentTZ(new Date(String(value))).tz('Asia/Bangkok').format(format);
  if(string) {
    if(thai) {
      string = string.split('/');
      return `${string[0]}/${string[1]}/${parseInt(string[2])+543}`;
    } else {
      return string;
    }
  } else {
    return '';
  }
}
export const formatDate = _formatDate;
export const formatTime = (value=null, format='HH:mm') => {
  let string;
  if(!value) string = momentTZ(new Date()).tz('Asia/Bangkok').format(format);
  else string = momentTZ(new Date(String(value))).tz('Asia/Bangkok').format(format);
  if(string) {
    return string;
  } else {
    return '';
  }
}

export const nextDays = (n=0, value=null) => {
  const _today = value? new Date(value): new Date();
  return new Date(_today.setDate(_today.getDate() + n));
}
export const diffDays = (date1, date2) => {
  const _date1 = new Date(_formatDate(date1, 'YYYY-MM-DD HH:mm'));
  const _date2 = new Date(_formatDate(date2, 'YYYY-MM-DD HH:mm'));
  const _diff = Math.abs((+new Date(_date1)) - (+new Date(_date2))) /1000;
  if(_diff >= 2*60*60*24) return `${Math.floor(_diff /60/60/24)} วัน`;
  if(_diff >= 60*60*24) return `1 วัน`;
  if(_diff >= 60*60) return `${Math.ceil(_diff /60/60)} ชั่วโมง`;
  return `${Math.ceil(_diff /60)} นาที`;
}

export const digitsCeil = (val) => {
  let digits = `${Math.round(val)}`.length - 1;
  let res = Math.ceil(val / 10**digits) * 10**digits;
  if((res - val) / 10**digits > 0.5){
    return res - 5 * 10**(digits-1);
  }else{
    return res;
  }
}

export const displayMonth = (i=0) => {
  return i >= 0 && i < 12? [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฏาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
  ][i]: '';
}
export const displayDay = (i=0) => {
  return i >= 0 && i < 7? [
    'วันอาทิตย์', 'วันจันทร์', 'วันอังคาร', 'วันพุธ', 'วันพฤหัสบดี', 'วันศุกร์', 'วันเสาร์',
  ][i]: '';
}

export const storageBtoKB = (val=0) => val /1024;
export const storageKBtoB = (val=0) => val *1024;
export const storageBtoMB = (val=0) => val /1024**2;
export const storageMBtoB = (val=0) => val *1024**2;
export const storageBtoGB = (val=0) => val /1024**3;
export const storageGBtoB = (val=0) => val *1024**3;
export const displayStorage = (val=0) => {
  if(val >= 1024**3) return `${Math.round(val /1024**3 *100)/100} GB`;
  else if(val >= 1024**2) return `${Math.round(val /1024**2 *100)/100} MB`;
  else if(val >= 1024) return `${Math.round(val /1024)} KB`;
  else return `${val} B`;
}

const checkStorageScale = (_val) => {
  if(_val >= 1024**3) return 1024**3;
  else if(_val >= 1024**2) return 1024**2;
  else if(_val >= 1024) return 1024;
  return 1;
}
const checkStorageScaleLargest = (_val, _scale) => {
  if(_val >= 1024**3) return 1024**3;
  else if(_val >= 1024**2 && _scale < 1024**2) return 1024**2;
  else if(_val >= 1024 && _scale < 1024) return 1024;
  return _scale;
}
export const totalStorages = (vals=[]) => {
  let _scale = 1;
  for(let i=0; i<vals.length; i++){
    if(_scale === 1024**3) continue;
    _scale = checkStorageScaleLargest(vals[i], _scale);
  }
  let _total = 0;
  vals.forEach(val => {
    const _selfScale = checkStorageScale(val);
    const _temp = Math.round(val /_selfScale *100)/100;
    _total += _temp *_selfScale /_scale;
  });
  return Math.round(_total *_scale);
}

export const scrollToRef = (ref) => {
  if(ref && ref.current){
    window.scrollTo({
      top: Math.max(0, (ref.current?.offsetTop ?? 0) - 125),
      left: 0, behavior: 'smooth',
    });
  }
}

export const getValueOrDefault = (val, _default='') => {
  return val ?? _default;
}
export const checkPreventDefault = (e=null) => {
  if(e) e.preventDefault();
}
export const cleanValue = (val, isNumber=false) => {
  if(isNumber){
    val = val || val===0? Number(val): '';
    val = isNaN(val)? null: val;
  }
  return val;
}

export const checkInputSubmit = (e=null, callback=()=>{}) => {
  if(e?.keyCode === 13) callback(e)
}

export const isSafeUrl = (url='') => {
  if(!url) return false;
  const _url = url.replace('http://', '').replace('https://', '').replace('www.', '');
  const allowedChars = /[-a-zA-Z0-9@:%._~#=]/;
  if(!allowedChars.test(_url)) return false;
  const urls = _url.split('/');
  if(urls[0] === 'pth-be.pttplc.com') return true;
  if(urls[0] === 'pth-be-dev.pttplc.com') return true;
  if(urls[0] === 'pth-be-sit.pttplc.com') return true;
  if(urls[0] === 'pth-be-uat.pttplc.com') return true;
  if(urls[0] === 'localhost:5100') return true;
  return false;
}

export const atLeastOneEmpty = (data=[]) => {
  if(!data || !data.length) return true;
  return data.some(val => !val);
}

export const redirectToUrl = (_status, _url) => {
  if(['authenticated', 'unauthenticated'].indexOf(_status) < 0) return '';

  if(!_url) return '/';
  if(_status === 'unauthenticated') return `/?url=${_url}`;
  
  const _urls = _url.split('-');
  if(_urls[0] === 'expirations') return `/internal/expirations/${_urls[1]}`;
  if(_urls[0] === 'sharedmedia') return `/internal/user/shared-medias/${_urls[1]}`;
  if(_urls[0] === 'sharedfolder'){
    let _redirect = `/internal/medias/${_urls[1]}/${_urls[2]}`;
    if(_urls[3]) _redirect += `/${_urls[3]}`;
    return _redirect;
  }
  return '/';
}
