import {
  APP_INIT, APP_SETTINGS, APP_SCREEN_WIDTH, MENU_ACTIVE,
  MEDIA_LOADER, MEDIA_LOADER_RESET, MEDIA_SETTINGS,
  FORCE_SIGNOUT,
} from '../actions/types';

const initialState = {
  init: true,
  settings: {},
  screenWidth: 0,
  menuActive: 0,

  mediaLoader: 0,
  mediaSettings: [],

  forceSignout: 0,
};

const appReducer = (state = initialState, action) => {
  switch(action.type) {

    case APP_INIT:
      return { ...state, init: action.payload };
    
    case APP_SETTINGS:
      return { ...state, settings: action.payload };
      
    case APP_SCREEN_WIDTH:
      return { ...state, screenWidth: action.payload };
    case MENU_ACTIVE:
      return { ...state, menuActive: action.payload };

    case MEDIA_LOADER:
      return { ...state, mediaLoader: state.mediaLoader+1 };
    case MEDIA_LOADER_RESET:
      return { ...state, mediaLoader: 0 };
    case MEDIA_SETTINGS:
      return { ...state, mediaSettings: action.payload };
      
    case FORCE_SIGNOUT:
      if(action.payload) return { ...state, forceSignout: action.payload === 2? 2: state.forceSignout+1 };
      else return { ...state, forceSignout: 0 };

    default:
      return state;
  }
};

export default appReducer;