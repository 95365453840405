import { createTheme } from '@mui/material';
import * as echarts from 'echarts';

export const MuiTheme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans Thai',
      'Roboto',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#5a8dee',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1b1264',
      contrastText: '#ffffff',
    },
    softPrimary: {
      main: `rgba(90,141,238,.1)`,
      dark: `rgba(90,141,238,.2)`, 
      contrastText: '#5a8dee',
    },
    softSecondary: {
      main: `rgba(27,18,100,.1)`,
      dark: `rgba(27,18,100,.2)`, 
      contrastText: '#1b1264',
    },
    warning: {
      main: '#fbc24d',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f5334f',
      contrastText: '#ffffff',
    },
    default: {
      main: `#eeeeee`,
      dark: `#e4e4e4`, 
      contrastText: '#333333',
    },
    white: {
      main: '#ffffff',
      contrastText: '#454f5b',
    },
    dark: {
      main: '#161c24',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiButton: { 
      styleOverrides: { 
        root: {
          textTransform: 'unset',
          borderRadius: '.5rem',
          whiteSpace: 'nowrap',
          fontWeight: 500,
        },
        sizeMedium: {
          fontSize: '.9375rem',
          paddingTop: '.5rem',
          paddingBottom: '.5rem',
        },
        sizeLarge: {
          fontSize: '1.0625rem',
          paddingTop: '.625rem',
          paddingBottom: '.625rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeigt: 0,
          borderRadius: '.5rem',
        },
        input: {
          padding: '.835rem .75rem',
          fontSize: '1rem',
          lineHeight: 1.5,
          background: '#ffffff',
        },
        sizeSmall: {
          input: {
            padding: '.6rem .75rem',
            fontSize: '1rem',
            lineHeight: 1.5,
            background: '#ffffff',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minHeigt: 0,
          borderRadius: '.5rem',
        },
        select: {
          padding: '.6rem .75rem',
          fontSize: '1rem',
          lineHeight: 1.5,
          background: '#ffffff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '.5rem',
          paddingBottom: '.5rem',
          fontSize: '.9375rem',
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '.5rem',
          border: '1px solid #efefef',
          boxShadow: '0 3px 6px rgba(0,0,0,.05)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  },
})

export const chartColor = (i=0, type=1) => {
  const _colors = type === 1? [
    new echarts.graphic.LinearGradient(0, 0, 0, 1,
      [{ offset: 0, color: '#ffae00' }, { offset: 1, color: '#ff2784' }]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1,
      [{ offset: 0, color: '#04edd8' }, { offset: 1, color: '#6334fe' }]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1,
      [{ offset: 0, color: '#ff2484' }, { offset: 1, color: '#6732fe' }]),
  ]: [
    new echarts.graphic.LinearGradient(0, 0, 1, 0,
      [{ offset: 0, color: '#ff2784' }, { offset: 1, color: '#ffae00' }]),
    new echarts.graphic.LinearGradient(0, 0, 1, 0,
      [{ offset: 0, color: '#6334fe' }, { offset: 1, color: '#04edd8' }]),
    new echarts.graphic.LinearGradient(0, 0, 1, 0,
      [{ offset: 0, color: '#6732fe' }, { offset: 1, color: '#ff2484' }]),
  ];
  return _colors[i % _colors.length];
}

export const chartFont = () => {
  return 'Noto Sans Thai, Roboto, sans-serif';
}
export const chartColorLabel = (isDark=0) => {
  return isDark? '#ffffff': '#555555';
}
export const chartColorLegend = (isDark=0) => {
  return isDark? '#eeeeee': '#777777';
}
export const chartColorMainLine = (isDark=0) => {
  return isDark? 'rgb(227,226,236,.6)': 'rgb(227,226,236,.8)';
}
export const chartColorSplitLine = (isDark=0) => {
  return isDark? 'rgb(227,226,236,.2)': 'rgb(227,226,236,.4)';
}
