import { useContext } from 'react';
import AuthContext from './context/AuthContext';
import ConfigContext from './context/ConfigContext';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MuiTheme } from './helpers/theme';

import PageLoader from './components/PageLoader';
import AlertPopup from './components/AlertPopup';

import RedirectPage from './views/redirect';
import { AuthFailedDeviceIdPage, AuthSignInPage } from './views/auth';
import RoutesInternal from './routes/routes.internal';

const checkRoute = (configReady, status, statusVal) => {
  return configReady && status === statusVal;
}

function App() {
  const { configReady } = useContext(ConfigContext);
  const { status, permissions } = useContext(AuthContext);
  return (
    <ThemeProvider theme={MuiTheme}>
      <BrowserRouter>
        {checkRoute(configReady, status, 'failedDeviceId')? (
          <Routes>
            <Route path="*" element={<AuthFailedDeviceIdPage />} />
          </Routes>
        ): checkRoute(configReady, status, 'unauthenticated')? (
          <Routes>
            <Route path="/" element={<AuthSignInPage />} />
            <Route path="/auth/signin" element={<AuthSignInPage />} />
            
            <Route path="/redirect" element={<RedirectPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        ): checkRoute(configReady, status, 'authenticated')? (
          <RoutesInternal permissions={permissions} />
        ): (<PageLoader />)}
        <AlertPopup />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;